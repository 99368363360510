export type DataToReplace = { [x: string]: string };

export function replaceData(path: string, data: DataToReplace) {
    if (!data) return path;

    return Object.keys(data).reduce((acc, curr) => {
        return acc?.replace(`[${curr}]`, data[curr]);
    }, path);
}

export function getReplaceDataQueryKeyParams(data: DataToReplace) {
    if (!data) return '';

    return Object.entries(data)?.reduce(
        (acc, [id, value]) => `${acc};${id}-${value}`,
        ''
    );
}
