import { defineMessages } from 'react-intl';

export const formErrorMessages = {
    ...defineMessages({
        NO_PERMISSION: {
            id: 'error.user-creation.missing-permission',
            defaultMessage: 'Vous devez ajouter au moins une permission.',
        },
    }),
};
