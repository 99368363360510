import { FormattedMessage } from 'react-intl';
import {
    CoreModal,
    CoreModalBody,
    CoreModalContent,
    CoreModalHeader,
    CoreModalFooter,
} from 'design-system/components/Modal';
import { coreSharedMessages } from 'core';
import { Button, Text } from '@chakra-ui/react';
import { PostSuccessModalComponentProps } from 'lib/form-wizard/types';
import { primoBankDetailsStepMessages } from '../i18n';

export default function BankDetailsConfirmationStep({
    isOpen,
    onClose,
    onConfirm,
}: PostSuccessModalComponentProps) {
    return (
        <CoreModal isOpen={isOpen} onClose={onClose} size="sm">
            <CoreModalContent>
                <CoreModalHeader>
                    <FormattedMessage
                        {...primoBankDetailsStepMessages.bankDetailsStartSignatureTitle}
                    />
                </CoreModalHeader>
                <CoreModalBody>
                    <Text lineHeight="normal">
                        <FormattedMessage
                            {...primoBankDetailsStepMessages.bankDetailsStartSignatureDescription}
                        />
                    </Text>
                </CoreModalBody>
                <CoreModalFooter>
                    <Button onClick={onClose} w="full" fontWeight="bold">
                        <FormattedMessage {...coreSharedMessages.back} />
                    </Button>
                    <Button
                        colorScheme="primary"
                        fontWeight="bold"
                        onClick={onConfirm}
                        w="full">
                        <FormattedMessage {...coreSharedMessages.continue} />
                    </Button>
                </CoreModalFooter>
            </CoreModalContent>
        </CoreModal>
    );
}
