import { WidgetType } from 'core';
import dayjs from 'dayjs';
import { Beneficiary } from 'lib/primo/types';

type formBeneficiariesProps = {
    hasMoreThan24Children: boolean;
    hasAlreadyPartner: boolean;
    defaultValues?: Partial<Beneficiary>;
};

const formBeneficiaryChoice = ({
    hasMoreThan24Children,
    hasAlreadyPartner,
    defaultValues = null,
}: formBeneficiariesProps) => ({
    id: 'beneficiary-choice',
    iri: '',
    widgets: [
        {
            id: 'beneficiary.type.title',
            title: 'beneficiary.type.title',
            label: 'beneficiary.type.title',
            defaultValue: defaultValues
                ? defaultValues['beneficiary.type.title']
                : null,
            description: null,
            placeholder: null,
            help: null,
            oneColumn: true,
            options: [
                !hasAlreadyPartner && {
                    id: 'partner',
                    label: 'beneficiary.status.partner',
                },
                !hasMoreThan24Children && {
                    id: 'child',
                    label: 'beneficiary.status.child',
                },
            ].filter(Boolean),
            validations: {
                required: true,
            },
            type: 'radio' as WidgetType,
        },
    ],
});

const formBeneficiaryPartnerInformations = (
    defaultValues: Partial<Beneficiary> = null
) => ({
    id: 'beneficiary-informations',
    iri: '',
    widgets: [
        {
            id: 'gender',
            title: 'user-infos.gender.title',
            label: 'user-infos.gender.title',
            defaultValue: defaultValues?.gender || null,
            description: null,
            placeholder: null,
            help: null,
            oneColumn: true,
            options: [
                {
                    id: 'male',
                    label: 'user-infos.gender.male',
                },
                {
                    id: 'female',
                    label: 'user-infos.gender.female',
                },
            ],
            validations: {
                required: true,
            },
            type: 'radio' as WidgetType,
        },
        {
            id: 'lastName',
            title: 'user-infos.last-name.title',
            label: 'user-infos.last-name.title',
            defaultValue: defaultValues?.lastName || '',
            description: null,
            placeholder: 'user-infos.last-name.title',
            help: null,
            options: [],
            validations: {
                allowNumbers: false,
                allowLetters: true,
                allowSpecialCharacters: true,
                lengthMax: 50,
                required: true,
            },
            type: 'text' as WidgetType,
        },
        {
            id: 'firstName',
            title: 'user-infos.first-name.title',
            label: 'user-infos.first-name.title',
            defaultValue: defaultValues?.firstName || '',
            description: null,
            placeholder: 'user-infos.first-name.title',
            help: null,
            options: [],
            validations: {
                allowNumbers: false,
                allowLetters: true,
                allowSpecialCharacters: true,
                lengthMax: 50,
                required: true,
            },
            type: 'text' as WidgetType,
        },
        {
            title: 'date.birth-date.title',
            id: 'birthDate',
            placeholder: 'date.placeholder',
            defaultValue: defaultValues?.birthDate || null,
            validations: {
                required: true,
                dateMin: null,
                dateMax: dayjs(),
            },
            type: 'date' as WidgetType,
        },
        {
            title: 'social-security-number.partner-title',
            id: 'socialSecurityNumberDetails.ownSocialSecurityNumber',
            placeholder: 'social-security-number.placeholder',
            defaultValue:
                defaultValues?.socialSecurityNumberDetails
                    .ownSocialSecurityNumber || '',
            validations: {
                required: true,
                allow0: true,
            },
            type: 'socialSecurityNumber' as WidgetType,
        },
        {
            id: 'otherComplementaryHealthInsurance',
            title: 'other-complementary-health-insurance.title',
            label: 'other-complementary-health-insurance.title',
            defaultValue:
                defaultValues?.otherComplementaryHealthInsurance.toString() ||
                null,
            description: null,
            placeholder: null,
            help: null,
            oneColumn: true,
            options: [
                {
                    id: 'true',
                    label: 'answer.yes',
                },
                {
                    id: 'false',
                    label: 'answer.no',
                },
            ],
            validations: {
                required: true,
            },
            type: 'radio' as WidgetType,
        },
        {
            id: 'organizationCode',
            title: 'organization-code.title',
            label: 'organization-code.title',
            defaultValue: defaultValues?.organizationCode || '',
            description: 'organization-code.primo-description',
            placeholder: 'organization-code.placeholder',
            help: null,
            options: [],
            validations: {
                allowNumbers: true,
                allowLetters: false,
                allowSpecialCharacters: false,
                lengthMin: 9,
                lengthMax: 9,
                required: false,
            },
            type: 'text' as WidgetType,
        },
        {
            id: 'localRegime',
            defaultValue: defaultValues?.localRegime || false,
            description: 'social-security.local-regime.description',
            help: null,
            placeholder: null,
            title: null,
            type: 'checkbox',
            validations: {
                required: false,
            },
        },
    ],
});

const formBeneficiaryChildInformations = (
    defaultValues: Partial<Beneficiary> = null
) => ({
    ...formBeneficiaryPartnerInformations(defaultValues),
    widgets: [
        ...formBeneficiaryPartnerInformations(defaultValues).widgets.slice(
            0,
            4
        ),
        {
            title: 'social-security-number.child-title',
            id: 'socialSecurityNumberDetails.ownSocialSecurityNumber',
            placeholder: 'social-security-number.placeholder',
            defaultValue:
                defaultValues?.socialSecurityNumberDetails
                    .ownSocialSecurityNumber || '',
            validations: {
                required: true,
                allow0: true,
            },
            type: 'socialSecurityNumber' as WidgetType,
        },
        {
            id: 'socialSecurityNumberDetails.socialSecurityConnection',
            title: 'social-security-number.child-attachment.title',
            label: 'social-security-number.child-attachment.title',
            defaultValue:
                defaultValues?.socialSecurityNumberDetails
                    .socialSecurityConnection || null,
            description: 'social-security-number.child-attachment.description',
            placeholder: null,
            help: null,
            oneColumn: true,
            options: [
                {
                    id: 'main',
                    label: 'social-security-number.child-attachment.yes-main',
                    subWidget: {
                        title: 'social-security-number.attached-title',
                        id: 'socialSecurityNumberDetails.socialSecurityConnection.ownSocialSecurityNumber',
                        placeholder: 'social-security-number.placeholder',
                        description:
                            'social-security-number.attached-description',
                        defaultValue:
                            defaultValues?.socialSecurityNumberDetails
                                .attachedSocialSecurityNumber || '',
                        validations: {
                            required: true,
                            allow0: true,
                        },
                        type: 'socialSecurityNumber' as WidgetType,
                    },
                },
                {
                    id: 'partner',
                    label: 'social-security-number.child-attachment.yes-partner',
                    subWidget: {
                        title: 'social-security-number.attached-title',
                        id: 'socialSecurityNumberDetails.socialSecurityConnection.ownSocialSecurityNumber',
                        placeholder: 'social-security-number.placeholder',
                        description:
                            'social-security-number.attached-description',
                        defaultValue:
                            defaultValues?.socialSecurityNumberDetails
                                .attachedSocialSecurityNumber || '',
                        validations: {
                            required: true,
                            allow0: true,
                        },
                        type: 'socialSecurityNumber' as WidgetType,
                    },
                },
                {
                    id: 'child',
                    label: 'social-security-number.child-attachment.no',
                },
            ],
            validations: {
                required: true,
            },
            type: 'conditionalRadio' as WidgetType,
        },
        ...formBeneficiaryPartnerInformations(defaultValues).widgets.slice(
            5,
            formBeneficiaryPartnerInformations(defaultValues).widgets.length
        ),
    ],
});

export {
    formBeneficiaryChoice,
    formBeneficiaryPartnerInformations,
    formBeneficiaryChildInformations,
};
