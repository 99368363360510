import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { APIError } from 'core';

const path = '/api/proxy/primo/validation';

async function postValidation(data) {
    try {
        await axios.post(path, data);
    } catch (error) {
        throw {
            code: error.response?.status,
            errors: error.response?.data?.errors,
        };
    }
}

export function usePostValidation() {
    return useMutation<void, APIError, unknown>({
        mutationFn: postValidation,
    });
}
