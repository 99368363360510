import type { NextApiRequest, NextApiResponse } from 'next';
import { IS_DEV_ENV } from 'core/consts';
import cookie from 'cookie';

const KEY = 'auth-username';

export const authUsernameHandler = async (
    req: NextApiRequest,
    res: NextApiResponse
) => {
    const { method, body } = req;

    try {
        switch (method) {
            case 'GET': {
                const username = cookie.parse(req.headers.cookie || '')[KEY];

                return res.status(200).json({ username });
            }

            case 'POST': {
                res.setHeader(
                    'Set-Cookie',
                    cookie.serialize(KEY, body.username, {
                        httpOnly: true,
                        sameSite: 'lax',
                        maxAge: 15778800, // 6 months
                        secure: !IS_DEV_ENV,
                        path: '/',
                    })
                );

                return res.status(201).end();
            }

            case 'DELETE': {
                res.setHeader(
                    'Set-Cookie',
                    cookie.serialize(KEY, '', {
                        httpOnly: true,
                        sameSite: 'lax',
                        expires: new Date(0),
                        secure: !IS_DEV_ENV,
                        path: '/',
                    })
                );
                return res.status(204).end();
            }
        }
    } catch (err) {
        return res.status(500).json({ error: err.message });
    }
};
