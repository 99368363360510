import { useQueryClient } from '@tanstack/react-query';
import { useRedirect } from 'core';
import { useUserContext } from 'lib/user';
import { SATISFACTION_SESSION_KEY } from 'lib/satisfaction';
import { TELECONSULTATION_DATA_STORAGE_KEY } from 'lib/teleconsultation/consts/teleconsultationFormConfig';
import { ONBOARDING_V2_SESSION_KEY } from 'components/onboarding/v2/consts';
import { PRIMO_STORAGE_KEY } from 'lib/primo/const/steps';

type ClearUserDataProps = Partial<{
    redirectPath?: string;
    shouldRedirect?: boolean;
}>;

export function useClearUserData() {
    const { redirect } = useRedirect();
    const queryClient = useQueryClient();
    const { clearUserInfos } = useUserContext();

    const clearUserData = async (props: ClearUserDataProps = {}) => {
        const { redirectPath, shouldRedirect } = {
            redirectPath: '/login',
            shouldRedirect: true,
            ...props,
        };
        queryClient.clear();
        window.sessionStorage.removeItem(TELECONSULTATION_DATA_STORAGE_KEY);
        window.sessionStorage.removeItem(SATISFACTION_SESSION_KEY);
        window.sessionStorage.removeItem(ONBOARDING_V2_SESSION_KEY);
        window.sessionStorage.removeItem(PRIMO_STORAGE_KEY);
        clearUserInfos();
        if (shouldRedirect) await redirect(redirectPath);
    };

    return { clearUserData };
}
