import { defineMessages } from 'react-intl';

export const primoHelpMessages = defineMessages({
    helpTitle: {
        id: 'workflow-primo.help.title',
        defaultMessage: 'Vous avez besoin d’aide ?',
    },
    helpGetSSCTitle: {
        id: 'workflow-primo.help.get-social-security-certificate.title',
        defaultMessage:
            'Comment récupérer votre attestation de Sécurité sociale ?',
    },
    helpGetSSCDescription: {
        id: 'workflow-primo.help.get-social-security-certificate.description',
        defaultMessage:
            'Vous pouvez télécharger votre attestation de Sécurité sociale sur votre espace personnel sur le site Ameli.fr ou ou auprès de de votre caisse de Sécurité sociale (MSA, CNMSS…).<br></br><br></br>Votre code organisme de rattachement se trouve sur votre attestation de Sécurité sociale.',
    },
    helpContactManagementCenterTitle: {
        id: 'workflow-primo.help.contact-management-center.title',
        defaultMessage: 'Contactez votre centre de gestion',
    },
    helpContactManagementCenterDescription: {
        id: 'workflow-primo.help.contact-management-center.description',
        defaultMessage:
            '<b>Téléphone : </b><br></br>{tel}<br></br><br></br><b>Adresse : </b><br></br>{address}',
    },
});

export const primoExitMessages = defineMessages({
    exitTitle: {
        id: 'workflow-primo.exit.title',
        defaultMessage: 'Voulez vous vraiment quitter ?',
    },
    exitDescription: {
        id: 'workflow-primo.exit.description',
        defaultMessage:
            '<b>Attention, vous êtes sur le point de quitter le parcours d’adhésion à votre complémentaire santé. Assurez-vous d’avoir terminé le parcours.</b><br></br><br></br>Les étapes précédentes seront sauvegardées.',
    },
});

export const primoWelcomeMessages = defineMessages({
    welcomeTitle: {
        id: 'workflow-primo.welcome.title',
        defaultMessage: 'Bienvenue sur votre espace adhésion',
    },
    welcomeDescription: {
        id: 'workflow-primo.welcome.description',
        defaultMessage:
            'Réalisez en quelques minutes votre adhésion pour bénéficier de tous les services de votre complémentaire santé.',
    },
    welcomeCheckDataTitle: {
        id: 'workflow-primo.welcome.check-data.title',
        defaultMessage: 'Vos informations sont-elles correctes ?',
    },
    welcomeCheckDataIncorrectData: {
        id: 'workflow-primo.welcome.check-data.incorrect-data',
        defaultMessage: 'Non, ces informations sont erronées',
    },
    welcomeCheckDataCorrectData: {
        id: 'workflow-primo.welcome.check-data.correct-data',
        defaultMessage: 'Oui, ces informations sont justes',
    },
    welcomeInfoIncorrectInfoTitle: {
        id: 'workflow-primo.welcome.check-data.incorrect-data-alert-title',
        defaultMessage:
            'Avant de continuer votre adhésion, contactez votre centre de gestion pour mettre à jour vos informations.',
    },
    welcomeInfoIncorrectInfoDescription: {
        id: 'workflow-primo.welcome.check-data.incorrect-data-alert-description',
        defaultMessage: 'Numéro du centre de gestion : <b>{phoneNumber}</b>',
    },
    welcomeInfoMissingDataTitle: {
        id: 'errors.workflow-primo.welcome.missing-data-title',
        defaultMessage:
            'Nous ne pouvons pas afficher vos informations actuellement, car certaines données sont manquantes.',
    },
    welcomeInfoMissingDataDescription: {
        id: 'errors.workflow-primo.welcome.missing-data-description',
        defaultMessage:
            'Veuillez contacter le centre de gestion pour mettre vos informations à jour.<br></br>',
    },
    welcomeProfileBirthDate: {
        id: 'profile.birth-date',
        defaultMessage: 'Date de naissance',
    },
    welcomeProfileSocialSecurityNumber: {
        id: 'profile.social-security-number',
        defaultMessage: 'N° de Sécurité sociale',
    },
});

export const primoIntroductionMessages = defineMessages({
    introductionDoc: {
        id: 'workflow-primo.welcome.documents-list-title',
        defaultMessage:
            'Avant de commencer, munissez-vous des documents suivants :',
    },
    introductionDocList: {
        id: 'workflow-primo.welcome.documents-list-description',
        defaultMessage: '<l>• RIB</l><l>• Attestation de Sécurité sociale</l>',
    },
    introductionPersonalisingContract: {
        id: 'workflow-primo.main-steps.personalising-contract',
        defaultMessage: 'Personnalisation de ma complémentaire santé',
    },
    introductionPersonalDetails: {
        id: 'workflow-primo.main-steps.personal-details',
        defaultMessage: 'Mes coordonnées',
    },
    introductionPersonalSpace: {
        id: 'workflow-primo.main-steps.personal-space',
        defaultMessage: 'Mon espace personnel',
    },
});

export const primoAddBeneficiariesStepMessages = defineMessages({
    addBeneficiariesStepTitle: {
        id: 'workflow-primo.main-steps.personalising-contract',
        defaultMessage: 'Personnalisation de ma complémentaire santé',
    },
    addBeneficiariesTitle: {
        id: 'workflow-primo.adding-beneficiaries.title',
        defaultMessage: 'Ajout de bénéficiaires',
    },
    beneficiariesTitle: {
        id: 'workflow-primo.adding-beneficiaries.beneficiaries-title',
        defaultMessage: 'Mes bénéficiaires',
    },
    beneficiariesDescription: {
        id: 'workflow-primo.adding-beneficiaries.beneficiaries-description',
        defaultMessage:
            "Vous avez la possibilité d'ajouter votre conjoint et/ou vos enfants pour qu'ils puissent également bénéficier des garanties de votre complémentaire santé.",
    },
    beneficiaryStatusPartner: {
        id: 'beneficiary.status.partner',
        defaultMessage:
            '{gender, select, male {Conjoint} female {Conjointe} other {Conjoint} }',
    },
    addBeneficiaryButton: {
        id: 'buttons.add-beneficiary',
        defaultMessage: 'Ajouter un bénéficiaire',
    },
    birthDate: {
        id: 'profile.birth-date',
        defaultMessage: 'Date de naissance',
    },
    socialSecurityNumber: {
        id: 'profile.social-security-number',
        defaultMessage: 'N° de Sécurité sociale',
    },
    socialSecurityOrganizationCode: {
        id: 'social-security.organization-code-dots',
        defaultMessage: 'Code organisme',
    },
    teletransmission: {
        id: 'social-security.teletransmission',
        defaultMessage: 'Télétransmission',
    },
    teletransmissionStatusDesired: {
        id: 'social-security.teletransmission-status.desired',
        defaultMessage: 'Souhaitée',
    },
    teletransmissionStatusInactivated: {
        id: 'social-security.teletransmission-status.inactivated',
        defaultMessage: 'Inactivée',
    },
    additionalContributionTitle: {
        id: 'workflow-primo.additional-contribution.title',
        defaultMessage: 'Complément de cotisation',
    },
    additionalContributionBeneficiariesDescription: {
        id: 'workflow-primo.additional-contribution.beneficiaries-description',
        defaultMessage:
            "L'ajout d'un bénéficiaire est à votre charge. <br></br>Ce montant sera directement prélevé sur votre compte bancaire.",
    },
    extendedBasicCover: {
        id: 'workflow-primo.additional-contribution.extended-basic-cover',
        defaultMessage:
            'Extension des garanties de base pour vos bénéficiaires',
    },
    contributionRIB: {
        id: 'workflow-primo.personalising-contractt.debit-method.contribution-rib',
        defaultMessage: 'Prélevée sur votre compte bancaire',
    },
    onSalary: {
        id: 'workflow-primo.personalising-contractt.debit-method.on-salary',
        defaultMessage: 'Prélevée sur votre salaire',
    },
    frequencyMonthly: {
        id: 'frequency.monthly',
        defaultMessage: 'mois',
    },
    nextButton: {
        id: 'buttons.next',
        defaultMessage: 'Suivant',
    },
    maxNumberReached: {
        id: 'workflow-primo.adding-beneficiaries.max-number-reached',
        defaultMessage: 'Nombre de bénéficiaires maximum atteint',
    },
});

export const primoBeneficiariesMessages = defineMessages({
    beneficiaryAddTitle: {
        id: 'workflow-primo.adding-beneficiaries.beneficiary-add.title',
        defaultMessage: 'Ajouter un bénéficiaire',
    },
    beneficiaryAddPartnerInfo: {
        id: 'workflow-primo.adding-beneficiaries.beneficiary-add.partner-info',
        defaultMessage:
            'Est considéré comme conjoint : le conjoint marié, le partenaire de pacs ou le concubin.',
    },
    teletransmissionPartnerInfo: {
        id: 'workflow-primo.adding-beneficiaries.beneficiary-add.teletransmission-partner-info',
        defaultMessage:
            "Si votre conjoint est déjà couvert par une complémentaire santé ou par la CMU, la télétransmission ne sera pas activée pour ce bénéficiaire. S’il souhaite bénéficier de la télétransmission, il doit d'abord la désactiver auprès de l’autre complémentaire santé. Une fois la démarche effectuée, il faudra qu’il se rapproche du centre de gestion.",
    },
    teletransmissionChildInfo: {
        id: 'workflow-primo.adding-beneficiaries.beneficiary-add.teletransmission-partner-info',
        defaultMessage:
            "Si votre enfant est déjà couvert par une complémentaire santé ou par la CMU, la télétransmission ne sera pas activée pour ce bénéficiaire. Si vous souhaitez qu’il bénéficie de la télétransmission, vous devez d'abord la désactiver auprès de l’autre complémentaire santé. Une fois la démarche effectuée, vous devrez vous rapprocher du centre de gestion.",
    },
    beneficiaryChangeTitle: {
        id: 'workflow-primo.adding-beneficiaries.beneficiary-change.title',
        defaultMessage: 'Modifier le bénéficiaire',
    },
    beneficiaryDeleteTitle: {
        id: 'workflow-primo.adding-beneficiaries.beneficiary-delete.title',
        defaultMessage: 'Supprimer le bénéficiaire',
    },
    beneficiaryDeleteDescription: {
        id: 'workflow-primo.adding-beneficiaries.beneciary-delete.description',
        defaultMessage:
            '<b>Voulez-vous vraiment supprimer ce bénéficiaire ?</b> <br></br>Toute action sera définitive.',
    },
});

export const primoReinforcingGuaranteesMessages = defineMessages({
    reinforcingGuaranteesTitle: {
        id: 'workflow-primo.reinforcing-basic-cover.title',
        defaultMessage: 'Renforcer mes garanties',
    },
    reinforcingGuaranteesDescription: {
        id: 'workflow-primo.reinforcing-basic-cover.description',
        defaultMessage:
            'Vous avez la possibilité de renforcer vos garanties en souscrivant une option et/ou une surcomplémentaire.<br></br>Cet ajout sera à votre charge.',
    },
    reinforcingGuaranteesInfo: {
        id: 'workflow-primo.reinforcing-basic-cover.guarantees-info',
        defaultMessage:
            "Pour connaître le détail de vos garanties, consultez votre Notice d'information fournie par votre employeur.",
    },
    optionsTitle: {
        id: 'workflow-primo.reinforcing-basic-cover.options-title',
        defaultMessage: 'Options disponibles',
    },
    optionsDescription: {
        id: 'workflow-primo.reinforcing-basic-cover.options-description',
        defaultMessage:
            'Une option permet de renforcer vos garanties, pour tous les bénéficiaires du contrat.',
    },
    pmssCalculation: {
        id: 'workflow-primo.reinforcing-basic-cover.pmss-caculation',
        defaultMessage: '{calculationAmount} % du PMSS',
    },
    pmssExplanation: {
        id: 'workflow-primo.reinforcing-basic-cover.pmss-explanation',
        defaultMessage:
            'Tarif estimé sur la base du Plafond Mensuel Sécurité Sociale ({pmssValue} € en {currentYear})',
    },
    supplementariesTitle: {
        id: 'workflow-primo.reinforcing-basic-cover.supplementaries-title',
        defaultMessage: 'Surcomplémentaires disponibles',
    },
    supplementariesDescription: {
        id: 'workflow-primo.reinforcing-basic-cover.supplementaries-description',
        defaultMessage:
            'Une surcomplémentaire permet de renforcer vos garanties sur des postes importants (frais optiques ou dentaires par exemple), pour tous les bénéficiaires du contrat.',
    },
    additionalProductsDescription: {
        id: 'workflow-primo.additional-contribution.additional-products-description',
        defaultMessage:
            "Les options et surcomplémentaires sélectionnées s'appliqueront à tous les bénéficiaires déclarés dans l'étape précédente.",
    },
    atYourCharge: {
        id: 'workflow-primo.additional-contribution.at-your-charge',
        defaultMessage: 'À votre charge',
    },
});

export const primoPersonalDataStepMessages = defineMessages({
    personalDataStepTitle: {
        id: 'workflow-primo.main-steps.personal-details',
        defaultMessage: 'Mes coordonnées',
    },
    personalDataStepSubtitle: {
        id: 'workflow-primo.personal-information',
        defaultMessage: 'Informations personnelles',
    },
    personalDataStepPostalAddress: {
        id: 'workflow-primo.personal-information.postal-address',
        defaultMessage: 'Mes coordonnées postales',
    },
    personalDataStepContactDetails: {
        id: 'workflow-primo.personal-information.contact-details',
        defaultMessage: 'Mes coordonnées de contact',
    },
});

export const primoSocialSecurityStepMessages = defineMessages({
    socialSecurityTitle: {
        id: 'workflow-primo.connection-to-social-security-system.title',
        defaultMessage: 'Lien avec la Sécurité sociale',
    },
    socialSecurityDescription: {
        id: 'workflow-primo.connection-to-social-security-system.description',
        defaultMessage:
            'Activez la télétransmission pour être remboursé(e) plus facilement et plus rapidement. Pour cela, veuillez renseigner votre code organisme de rattachement.',
    },
    socialSecurityOrganizationCodeInfo: {
        id: 'workflow-primo.connection-to-social-security-system.organization-code-info',
        defaultMessage:
            'Votre code organisme de rattachement se trouve sur votre attestation de Sécurité sociale.',
    },
    socialSecurityGetCertificate: {
        id: 'buttons.get-social-security-certificate',
        defaultMessage: 'Retrouver mon attestation de Sécurité sociale',
    },
});

export const primoBankDetailsStepMessages = defineMessages({
    bankDetailsTitle: {
        id: 'workflow-primo.bank-details.title',
        defaultMessage: 'Coordonnées bancaires',
    },
    bankDetailsRequiredSignatureDescription: {
        id: 'workflow-primo.bank-details.required-signature-descripion',
        defaultMessage:
            '<b>Vos coordonnées bancaires sont nécessaires pour le remboursement de vos soins et le règlement de vos cotisations.</b><br></br><br></br>Les informations qui seront renseignées dans le formulaire ci-dessous seront utilisées pour compléter votre mandat SEPA.',
    },
    bankDetailsNoRequiredSignatureDescription: {
        id: 'workflow-primo.bank-details.no-required-signature-descripion',
        defaultMessage:
            'Vos coordonnées bancaires sont nécessaires pour le remboursement de vos soins.',
    },
    bankDetailsMainTitle: {
        id: 'workflow-primo.bank-details.main-section.title',
        defaultMessage: 'Mes coordonnées bancaires',
    },
    bankDetailsMainDescription: {
        id: 'workflow-primo.bank-details.main-section.description',
        defaultMessage:
            'Vos remboursements et ceux de vos bénéficiaires seront effectués sur votre compte bancaire.',
    },
    bankDetailsStartSignatureTitle: {
        id: 'workflow-primo.bank-details.start-signature.title',
        defaultMessage: 'Vous allez débuter la signature du mandat SEPA',
    },
    bankDetailsStartSignatureDescription: {
        id: 'workflow-primo.bank-details.start-signature.description',
        defaultMessage:
            "<b>Une fois le mandat SEPA validé, vous ne pourrez plus revenir en arrière dans le parcours d’adhésion en ligne.</b><br></br><br></br>Avant de continuer, assurez-vous de l'exactitude des informations renseignées.",
    },
    bankDetailsPartnerDeleteTitle: {
        id: 'workflow-primo.bank-details.partner-delete.title',
        defaultMessage: 'Supprimer les coordonnées bancaires de votre conjoint',
    },
    bankDetailsPartnerDeleteDescription: {
        id: 'workflow-primo.bank-details.partner-delete.description',
        defaultMessage:
            '<b>Voulez-vous vraiment supprimer les coordonnées bancaires de votre conjoint ?</b><br></br><br></br>Toute action sera définitive.',
    },
    bankDetailsPartnerTitle: {
        id: 'bank-details.partner-title',
        defaultMessage: 'Coordonnées bancaires de mon conjoint',
    },
    bankDetailsPartnerDescription: {
        id: 'bank-details.partner-description',
        defaultMessage:
            "S'il le souhaite, vous pouvez ajouter les coordonnées bancaires de votre conjoint pour qu'il puisse être remboursé de ses soins directement sur son compte bancaire.",
    },
});

export const primoSepaMandateStepMessages = defineMessages({
    sepaMandateStepTitle: {
        id: 'workflow-primo.main-steps.sepa-mandate-signature',
        defaultMessage: 'Signature du mandat SEPA',
    },
    sepaMandateVerifyTitle: {
        id: 'workflow-primo.sepa-mandate.verify-title',
        defaultMessage: 'Vérification du mandat SEPA',
    },
    sepaMandateDocumentDescription: {
        id: 'workflow-signature.documents.description',
        defaultMessage:
            'Merci de vérifier les informations pré-remplies ci-dessous avant de valider.',
    },
    sepaMandateCodeVerificationTitle: {
        id: 'workflow-primo.sepa-mandate.code-verification',
        defaultMessage: 'Code de vérification',
    },
    sepaMandateCodeVerificationDescription: {
        id: 'workflow-primo.sepa-mandate.code-verification-description',
        defaultMessage:
            'Sélectionnez le numéro de téléphone sur lequel vous souhaitez recevoir votre code de vérification.',
    },
    sepaMandateSendCodeVerification: {
        id: 'workflow-primo.sepa-mandate.send-code-verification',
        defaultMessage: 'Envoyer le code',
    },
});

export const primoPendingValidationMessage = defineMessages({
    title: {
        id: 'errors.login.pending-validation-primo.title',
        defaultMessage: 'Vous avez bien finalisé votre adhésion en ligne',
    },
    description: {
        id: 'errors.login.pending-validation-primo.description',
        defaultMessage:
            "La validation de votre adhésion peut prendre jusqu'à 72h.<br></br>Vous ne pourrez accéder à {appName} qu'une fois votre adhésion validée.",
    },
    info: {
        id: 'errors.login.pending-validation-primo.info',
        defaultMessage:
            "Pour plus d'informations, nous vous invitons à contacter votre centre de gestion.",
    },
});

export const primoConfirmationStepMessage = defineMessages({
    title: {
        id: 'workflow-primo.confirmation.title',
        defaultMessage:
            "Félicitations, vous avez complété votre demande d'adhésion",
    },
    description: {
        id: 'workflow-primo.confirmation.description',
        defaultMessage:
            "La validation de votre adhésion peut prendre jusqu'à 72h. Vous ne pourrez accéder à votre espace personnel {appName} qu’une fois votre adhésion validée.<br></br><br></br>Votre carte de tiers payant vous sera adressée par voie postale.",
    },
    documentsListTitle: {
        id: 'workflow-primo.confirmation.documents-list-title',
        defaultMessage:
            '{nbDocuments, plural, one {Mon document} other {Mes documents}}',
    },
    documentsListDescription: {
        id: 'workflow-primo.confirmation.documents-list-description',
        defaultMessage:
            '{nbDocuments, plural, one {Nous vous conseillons de télécharger le document ci-dessous et de le conserver.} other {Nous vous conseillons de télécharger les documents ci-dessous et de les conserver.}}',
    },
    storeDownloadDescription: {
        id: 'workflow-primo.confirmation.store-download-description',
        defaultMessage:
            "Télécharger l'application {name} sur les différents stores",
    },
    goToMyAccount: {
        id: 'workflow-primo.confirmation.go-to-my-account',
        defaultMessage: 'Accéder à mon espace personnel',
    },
    missingDataTitle: {
        id: 'errors.workflow-primo.welcome.missing-data-title',
        defaultMessage:
            '<b>Nous ne pouvons pas afficher vos informations actuellement, car certaines données sont manquantes.</b>',
    },
    missingDataDescription: {
        id: 'errors.workflow-primo.welcome.missing-data-description',
        defaultMessage:
            'Veuillez contacter le centre de gestion pour mettre vos informations à jour. {phoneNumber, select, undefined{} null{} other{<br></br>Numéro du centre de gestion : {phoneNumber}}}',
    },
});

export const primoAccessCreationStepMessage = defineMessages({
    personalSpace: {
        id: 'workflow-primo.main-steps.personal-space',
        defaultMessage: 'Mon espace personnel',
    },
    title: {
        id: 'workflow-primo.access-creation.title',
        defaultMessage: 'Création des accès',
    },
    description: {
        id: 'workflow-primo.access-creation.description',
        defaultMessage:
            '<b>Teminez de remplir ce formulaire pour accéder à votre espace personnel {appName} grâce à vos nouveaux identifiants.</b><br></br>Votre identifiant de connexion est désormais votre numéro adhérent que vous pourrez retrouver sur votre carte de tiers payant.',
    },
});
