import { ComponentType } from 'react';
import { QueryStatus } from '@tanstack/react-query';
import { LayoutBoundaryType } from 'core';
import { getMagicScrollbarStyle } from 'design-system/lib/shared/helpers';
import { Markdown } from 'design-system/components';
import {
    CoreModal,
    CoreModalBody,
    CoreModalContent,
    CoreModalHeader,
} from 'design-system/components/Modal';

type TermsModalProps = {
    isOpen: boolean;
    status: QueryStatus;
    title: string;
    content: string;
    LayoutBoundary: ComponentType<LayoutBoundaryType>;
    layoutProps?: Partial<LayoutBoundaryType>;
    onClose: () => void;
};

export function TermsModal({
    isOpen,
    status,
    title,
    content,
    LayoutBoundary,
    layoutProps = {},
    onClose,
}: TermsModalProps) {
    return (
        <CoreModal
            size="4xl"
            isOpen={isOpen}
            onClose={onClose}
            isCentered={false}
            scrollBehavior="inside">
            <CoreModalContent>
                <CoreModalHeader>{title}</CoreModalHeader>
                <CoreModalBody
                    sx={{ ...getMagicScrollbarStyle('5', 'primary.main') }}>
                    <LayoutBoundary status={status} py="4" {...layoutProps}>
                        {content && <Markdown content={content} />}
                    </LayoutBoundary>
                </CoreModalBody>
            </CoreModalContent>
        </CoreModal>
    );
}
