import axios from 'axios';
import { APIError, FetchQueryParams } from 'core';
import { useQuery } from '@tanstack/react-query';
import { Username } from '../types';

export const usernamePath = `/api/proxy/primo/username`;

export async function fetchUsername({ queryKey: [urn] }: FetchQueryParams) {
    try {
        const res = await axios.get(urn);

        return res.data.data;
    } catch (error) {
        throw {
            code: error.response?.status,
        };
    }
}

export function useUsername(enabled: boolean = true) {
    return useQuery<Username, APIError>({
        queryKey: [usernamePath],
        queryFn: fetchUsername,
        enabled,
    });
}
