import { useState } from 'react';
import { Button, Container, HStack, Text } from '@chakra-ui/react';
import {
    DigitalSignature,
    FormErrors,
    VerificationCodePins,
    ViolationMessage,
    capitalizeHyphenatedName,
    coreErrors,
    coreSharedMessages,
    useSessionStorage,
} from 'core';
import { signatureMessages } from 'core/lib/signature';
import { useWindowBreakpoints } from 'design-system/hooks';
import { StepComponentProps } from 'lib/form-wizard/types';
import { PrimoContextData, PrimoStepsEnum } from 'lib/primo/types';
import {
    usePersonalData,
    usePostResendSms,
    usePostSignatureSepaCode,
} from 'lib/primo/api';
import {
    PRIMO_STORAGE_KEY,
    PRIMO_CONTEXT_DEFAULT_DATA,
    primoSteps,
} from 'lib/primo/const';
import LayoutBoundary from 'components/Layouts/LayoutBoundary';
import { FormattedMessage, useIntl } from 'react-intl';
import { sharedMessages } from 'lib/shared';
import { useToast } from 'design-system/components';

const LAYOUT_ERROR_CODES = [403, 500];

const LAYOUT_MESSAGES = {
    error: coreErrors.serviceUnavailableDescription,
    errorDescription: coreErrors.serviceUnavailable,
};

const DigitalSignatureStep = ({
    handleNextStep,
    resetStep,
}: StepComponentProps) => {
    const [code, setCode] = useState(null);
    const [validationErrors, setValidationErrors] = useState<
        ViolationMessage[]
    >([]);
    const [hasLayoutError, setHasLayoutError] = useState<boolean>(false);
    const { formatMessage } = useIntl();
    const { data: personalData, status: personalDataStatus } =
        usePersonalData();
    const { mutate: postResendSms } = usePostResendSms();
    const { mutate: postSignatureSepaCode, status: signatureSepaCodeStatus } =
        usePostSignatureSepaCode();
    const { isMobile } = useWindowBreakpoints();
    const { load } = useSessionStorage();

    const successToast = useToast({
        status: 'success',
        duration: 10000,
        isClosable: true,
        description: formatMessage(
            signatureMessages['success-message-new-verification-code']
        ),
    });

    const errorServiceUnavailableToast = useToast({
        status: 'error',
        description: formatMessage(coreErrors.serviceUnavailable),
    });

    const errorServiceNotFoundToast = useToast({
        status: 'error',
        description: formatMessage(coreErrors.serviceNotFound),
    });

    const primoContextData = load(
        PRIMO_STORAGE_KEY,
        PRIMO_CONTEXT_DEFAULT_DATA
    ) as PrimoContextData;
    const phoneNumber = primoContextData?.signaturePhoneNumber;

    const name = personalData
        ? capitalizeHyphenatedName(
              `${personalData.firstName} ${personalData.lastName}`
          )
        : null;

    const handleError = (error) => {
        if (LAYOUT_ERROR_CODES.includes(error?.code)) {
            setHasLayoutError(true);
        } else {
            setValidationErrors(error?.errors || []);
            window.scrollTo(0, 0);
        }
    };

    const onResend = () => {
        postResendSms(
            {},
            {
                onSuccess: () => successToast(),
                onError: (error) => {
                    switch (error?.code) {
                        case 403:
                            errorServiceNotFoundToast();
                            break;
                        default:
                            errorServiceUnavailableToast();
                            break;
                    }
                },
            }
        );
    };

    const onSubmit = () => {
        postSignatureSepaCode(
            { code },
            {
                onSuccess: () => handleNextStep(),
                onError: handleError,
            }
        );
    };

    const onClickErrorButton = () => {
        resetStep();
        setHasLayoutError(false);
    };

    return (
        <Container pt="12" mb="10">
            <LayoutBoundary
                status={hasLayoutError ? 'error' : personalDataStatus}
                px={0}
                py={0}
                {...(personalDataStatus === 'pending' ? { pt: '10' } : {})}
                errorButtons={[
                    {
                        text: coreSharedMessages.buttonBackHome,
                        onClick: onClickErrorButton,
                    },
                ]}
                messages={LAYOUT_MESSAGES}>
                {validationErrors.length > 0 && (
                    <FormErrors
                        errors={validationErrors}
                        wrapperProps={{ mb: 0 }}
                    />
                )}

                <Text
                    as="h3"
                    fontWeight="semibold"
                    fontSize="2xl"
                    fontFamily="mono">
                    <FormattedMessage
                        {...signatureMessages['verification-code-title']}
                    />
                </Text>
                <Text>
                    <FormattedMessage
                        {...signatureMessages['verification-code-description']}
                        values={{
                            phoneNumber:
                                (phoneNumber as string)?.replace(
                                    /(.{2})/g,
                                    '$1 '
                                ) || null,
                        }}
                    />
                </Text>
                <VerificationCodePins
                    handleInput={setCode}
                    isCodeValid={false}
                    onResend={onResend}
                />
                <DigitalSignature
                    name={name}
                    boxProps={{ bgColor: 'bg.colorlight' }}
                />
            </LayoutBoundary>

            <HStack justifyContent="flex-end" mt="10">
                <Button
                    bg="primary.50"
                    color="primary.main"
                    size={isMobile ? 'lg' : 'md'}
                    onClick={() =>
                        handleNextStep(
                            null,
                            primoSteps.findIndex(
                                (primoStep) =>
                                    primoStep.name ===
                                    PrimoStepsEnum.BANK_DETAILS
                            )
                        )
                    }>
                    <FormattedMessage {...sharedMessages.cancel} />
                </Button>
                <Button
                    alignSelf="end"
                    colorScheme="primary"
                    size={isMobile ? 'lg' : 'md'}
                    onClick={onSubmit}
                    isDisabled={
                        personalDataStatus !== 'success' || hasLayoutError
                    }
                    isLoading={signatureSepaCodeStatus === 'pending'}>
                    <FormattedMessage {...signatureMessages.sign_and_send} />
                </Button>
            </HStack>
        </Container>
    );
};

export default DigitalSignatureStep;
