import axios from 'axios';
import { APIError, FetchQueryParams } from 'core';
import { useQuery } from '@tanstack/react-query';
import { Document } from '../types';

const path = `/api/proxy/primo/documents`;

async function fetchDocuments({ queryKey: [urn] }: FetchQueryParams) {
    try {
        const res = await axios.get(urn);

        return res.data.data;
    } catch (error) {
        throw {
            code: error.response?.status,
        };
    }
}

export function useDocuments(enabled: boolean = true) {
    return useQuery<Document[], APIError>({
        queryKey: [path],
        queryFn: fetchDocuments,
        enabled,
    });
}
