import { WidgetKeys, WidgetType } from 'core';
import { BankDetails } from 'lib/primo';

// @TODO: More widgets will come soon
export const getPrimoBankDetailsForm = (
    bankDetails?: BankDetails
): WidgetKeys[] => [
    {
        id: 'bank-details',
        validations: {
            required: true,
        },
        type: 'bankDetails' as WidgetType,
        owner: {
            title: 'rib-details.owner.title',
            validations: {
                required: true,
                lengthMax: 31,
                allowLetters: true,
                allowSpecialCharacters: true,
                allowNumbers: false,
            },
            show: true,
            defaultValue: bankDetails?.owner,
        },
        iban: {
            title: 'rib-details.iban.title',
            placeholder: 'rib-details.iban.placeholder',
            validations: {
                required: true,
                lengthMax: 27,
                lengthMin: 27,
            },
            show: true,
            defaultValue: bankDetails?.iban,
        },
        bic: {
            title: 'rib-details.bic.title',
            validations: {
                required: true,
                lengthMax: 11,
            },
            show: true,
            defaultValue: bankDetails?.bic,
        },
        bank: {
            title: 'rib-details.bank-name.title',
            validations: {
                required: true,
                lengthMax: 50,
            },
            show: true,
            defaultValue: bankDetails?.bankName,
        },
        autoCompleteUri: '/primo/bank-details-info',
    },
    {
        validations: {
            acceptedMimeTypes: [
                'image/png',
                'image/apng',
                'image/vnd.mozilla.apng',
                'image/jpeg',
                'image/pjpeg',
                'application/pdf',
                'application/acrobat',
                'application/nappdf',
                'application/x-pdf',
                'image/pdf',
                'image/heic',
                'image/heic-sequence',
                'image/heif',
                'image/heif-sequence',
            ],
            maxSize: 5242880,
            totalMaxSize: 5242880,
            totalMaxFiles: 1,
            required: true,
        },
        fileCategories: [
            {
                help: null,
                validations: {
                    maxFiles: 1,
                    required: true,
                },
                id: 'rib',
                description: null,
                title: null,
                defaultValue: bankDetails?.bankFile,
            },
        ],
        help: null,
        description: null,
        title: 'attachments.rib.title',
        type: 'multipleFileUpload',
        id: 'bank-rib',
    },
];

export const getPrimoBankDetailsRequiredSignatureForm = (
    bankDetails?: BankDetails
): WidgetKeys[] => {
    const [lastInput, ...firstInputs] =
        getPrimoBankDetailsForm(bankDetails).reverse();

    return [
        ...firstInputs.reverse(),
        {
            type: 'text',
            title: 'contributions.signature-location.title',
            description: 'contributions.signature-location.primo-description',
            id: 'signature-location',
            validations: {
                required: true,
                allowLetters: true,
                allowSpecialCharacters: true,
                allowNumbers: false,
                lengthMax: 50,
            },
        },
        lastInput,
    ];
};
