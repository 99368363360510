import axios from 'axios';
import { APIError, FetchQueryParams } from 'core';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { BankDetails, PostBankDetails } from '../types';

type PostBeneficiaryBankDetailsProps = {
    bankDetails: PostBankDetails;
};

type PostBeneficiaryBankDetailsResponse = {
    meta: {
        next_step: string;
    };
};

export const bankDetailsPath = '/api/proxy/primo/bank-details';

export async function fetchBeneficiaryBankDetails({
    queryKey: [urn],
}: FetchQueryParams) {
    try {
        const res = await axios.get(urn);

        return res.data.data;
    } catch (error) {
        throw {
            code: error.response?.status,
        };
    }
}

export function useBeneficiaryBankDetails(enabled: boolean = true) {
    return useQuery<BankDetails, APIError>({
        queryKey: [bankDetailsPath],
        queryFn: fetchBeneficiaryBankDetails,
        enabled,
    });
}

async function postBeneficiaryBankDetails({
    bankDetails,
}: PostBeneficiaryBankDetailsProps) {
    try {
        const res = await axios.post(bankDetailsPath, bankDetails);

        return res.data;
    } catch (error) {
        throw {
            code: error.response?.status,
            errors: error.response?.data?.errors,
        };
    }
}

export function usePostBeneficiaryBankDetails() {
    const queryClient = useQueryClient();

    return useMutation<PostBeneficiaryBankDetailsResponse, APIError, unknown>({
        mutationFn: postBeneficiaryBankDetails,
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [bankDetailsPath],
            });
        },
    });
}

async function deleteBeneficiaryBankDetails() {
    try {
        await axios.delete(bankDetailsPath);
    } catch (error) {
        throw {
            code: error.response?.status,
            errors: error.response?.data?.errors,
        };
    }
}

export function useDeleteBeneficiaryBankDetails() {
    return useMutation<void, APIError, unknown>({
        mutationFn: deleteBeneficiaryBankDetails,
    });
}
