import axios from 'axios';
import { APIError, FetchQueryParams } from 'core';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AdditionalProducts, AdditionalProductsPost } from '../types';

export const additionalProductsPath = `/api/proxy/primo/additional-products`;

export async function fetchAdditionalProducts({
    queryKey: [urn],
}: FetchQueryParams) {
    try {
        const res = await axios.get(urn);

        return res.data.data;
    } catch (error) {
        throw {
            code: error.response?.status,
        };
    }
}

export function useAdditionalProducts(enabled: boolean = true) {
    return useQuery<AdditionalProducts, APIError>({
        queryKey: [additionalProductsPath],
        queryFn: fetchAdditionalProducts,
        enabled,
    });
}

async function postAdditionalProducts(
    additionalProducts: AdditionalProductsPost
) {
    try {
        await axios.post(additionalProductsPath, additionalProducts);
    } catch (error) {
        throw {
            code: error.response?.status,
            errors: error.response?.data?.errors,
        };
    }
}

export function usePostAdditionalProducts() {
    return useMutation<void, APIError, AdditionalProductsPost>({
        mutationFn: postAdditionalProducts,
    });
}
