import { FormValues } from 'core';
import { Logger } from 'scripts';
import { PostBankDetails } from '../types';

export const formatBeneficiaryBankDetails = (
    data: FormValues
): PostBankDetails => {
    try {
        const { name, content } = JSON.parse(data?.['bank-rib']?.filerib1);

        return {
            iban: data?.['bank-details']?.iban,
            bic: data?.['bank-details']?.bic,
            bankName: data?.['bank-details']?.bank,
            owner: data?.['bank-details']?.owner,
            signatureLocation: data?.['signature-location']?.value || null,
            bankFile: {
                name,
                content: content.replace('data:', '').replace(/^.+,/, ''),
            },
        };
    } catch (error) {
        Logger.error(`Error while parsing beneficiary rib file: ${error}`);
        return null;
    }
};
