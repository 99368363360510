import axios from 'axios';
import { APIError, FetchQueryParams } from 'core';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { GeneralRegime } from '../types';

export const generalRegimePath = `/api/proxy/primo/general-regime`;

export async function fetchGeneralRegime({
    queryKey: [urn],
}: FetchQueryParams) {
    try {
        const res = await axios.get(urn);

        return res.data.data;
    } catch (error) {
        throw {
            code: error.response?.status,
        };
    }
}

export function useGeneralRegime(enabled: boolean = true) {
    return useQuery<GeneralRegime, APIError>({
        queryKey: [generalRegimePath],
        queryFn: fetchGeneralRegime,
        enabled,
    });
}

async function postGeneralRegime(data: GeneralRegime) {
    try {
        await axios.post(generalRegimePath, data);
    } catch (error) {
        throw {
            code: error.response?.status,
            errors: error.response?.data?.errors,
        };
    }
}

export function usePostGeneralRegime() {
    const queryClient = useQueryClient();

    return useMutation<void, APIError, unknown>({
        mutationFn: postGeneralRegime,
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [generalRegimePath],
            });
        },
    });
}
