import { WidgetKeys } from 'core';
import { StepComponentProps } from 'lib/form-wizard/types';
import {
    BankDetails,
    GeneralRegime,
    PersonalData,
    PrimoStepsEnum,
    Username,
} from '../types';
import {
    fetchPersonalData,
    personalDataPath,
    fetchGeneralRegime,
    generalRegimePath,
    fetchBeneficiaryBankDetails,
    bankDetailsPath,
    fetchUsername,
    usernamePath,
} from '../api';
import { useQueryClient } from '@tanstack/react-query';
import {
    getPrimoBankDetailsForm,
    getPrimoBankDetailsRequiredSignatureForm,
    getPrimoGeneralRegimeForm,
    getPrimoPersonnalDataForm,
    getPrimoSendCodeVerificationForm,
    getPrimoAccessCreationForm,
} from '../const';
import { InternationalPhoneNumberProps } from 'core/components/forms/widgets/InternationalPhoneNumber';
import { fetchTerms, path as termsPath, UseCGU } from 'lib/user/api/useTerms';
import { siteConfig } from 'lib/site';

const { client_id } = siteConfig;

type Callbacks = {
    onStart: () => void;
    onSuccess: (
        prefillForm: (WidgetKeys | InternationalPhoneNumberProps)[]
    ) => void;
    onError: (error: { code: number }) => void;
    onEnd: () => void;
};

export const usePrefillForm = (
    formType: StepComponentProps['formType'],
    additionalData: StepComponentProps['additionalData']
) => {
    const queryClient = useQueryClient();

    const prefillForm = async (callbacks: Callbacks) => {
        try {
            callbacks.onStart();
            switch (formType) {
                case PrimoStepsEnum.INFORMATIONS: {
                    const personalData =
                        await queryClient.fetchQuery<PersonalData>({
                            queryKey: [personalDataPath],
                            queryFn: fetchPersonalData,
                        });

                    callbacks.onSuccess(
                        getPrimoPersonnalDataForm(personalData)
                    );
                    break;
                }
                case PrimoStepsEnum.SOCIAL_SECURITY: {
                    const generalRegime =
                        await queryClient.fetchQuery<GeneralRegime>({
                            queryKey: [generalRegimePath],
                            queryFn: fetchGeneralRegime,
                        });

                    callbacks.onSuccess(
                        getPrimoGeneralRegimeForm(generalRegime)
                    );
                    break;
                }
                case PrimoStepsEnum.BANK_DETAILS: {
                    const bankDetails =
                        await queryClient.fetchQuery<BankDetails>({
                            queryKey: [bankDetailsPath],
                            queryFn: fetchBeneficiaryBankDetails,
                        });

                    callbacks.onSuccess(
                        additionalData?.requiredSignature
                            ? getPrimoBankDetailsRequiredSignatureForm(
                                  bankDetails
                              )
                            : getPrimoBankDetailsForm(bankDetails)
                    );
                    break;
                }
                case PrimoStepsEnum.SEND_CODE_VERIFICATION_SEPA_MANDATE: {
                    const personalData =
                        await queryClient.fetchQuery<PersonalData>({
                            queryKey: [personalDataPath],
                            queryFn: fetchPersonalData,
                        });

                    callbacks.onSuccess(
                        getPrimoSendCodeVerificationForm(personalData)
                    );
                    break;
                }
                case PrimoStepsEnum.ACCESS_CREATION: {
                    const [gcuData] = await Promise.all([
                        queryClient.fetchQuery<UseCGU>({
                            queryKey: [
                                termsPath,
                                false,
                                client_id,
                                true,
                                'primo',
                            ],
                            queryFn: fetchTerms,
                        }),
                        queryClient.fetchQuery<Username>({
                            queryKey: [usernamePath],
                            queryFn: fetchUsername,
                        }),
                    ]);

                    callbacks.onSuccess(getPrimoAccessCreationForm(gcuData));
                    break;
                }
                default:
                    callbacks.onSuccess([]);
                    break;
            }
        } catch (error) {
            callbacks.onError(error);
        } finally {
            callbacks.onEnd();
        }
    };

    return prefillForm;
};
