import { Box, Button, Flex, HStack, Tooltip, VStack } from '@chakra-ui/react';
import { Heading, SafeFormattedMessage, coreSharedMessages } from 'core';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    primoAddBeneficiariesStepMessages,
    primoReinforcingGuaranteesMessages,
} from '../i18n';
import { useWindowBreakpoints } from 'design-system/hooks';
import { paymentFrequencyMessages } from 'lib/contracts';
import { OptionBase } from '../types';
import { Tag } from 'design-system/components';
import { InfoIcon } from 'design-system/icons';

type ReinforcingGuaranteesCardProps = {
    cardContent: OptionBase[];
    selectedCard: OptionBase | null;
    setSelectedCard: (card: OptionBase) => void;
    tagColor?: string;
};

const ReinforcingGuaranteesCard = ({
    cardContent,
    selectedCard,
    setSelectedCard,
    tagColor = 'guarantees.option',
}: ReinforcingGuaranteesCardProps) => {
    const { formatMessage } = useIntl();
    const { isMobile } = useWindowBreakpoints();

    return (
        <Flex
            w={cardContent?.length > 1 ? 'full' : 'auto'}
            gap="4"
            flexDir={isMobile ? 'column' : 'row'}>
            {cardContent?.map((option) => {
                const truncatedAmount = option?.amount.toFixed(2);
                const isSelected =
                    selectedCard?.identifier === option?.identifier;

                return (
                    <VStack
                        key={option.identifier}
                        w={cardContent?.length > 1 ? 'full' : 'auto'}
                        textAlign="center"
                        rounded="md"
                        bg={isSelected ? 'primary.ultralight' : 'transparent'}
                        borderColor={
                            isSelected ? 'primary.main' : 'strokes.medium'
                        }
                        borderWidth="1px"
                        p="6"
                        spacing="8"
                        justifyContent="space-between">
                        <Tag
                            formattedText={option?.label}
                            bg={isSelected ? 'primary.main' : tagColor}
                            fontWeight={'bold'}
                            fontSize={'sm'}
                        />
                        <VStack spacing="4">
                            <VStack>
                                {option?.debitMethod === 'onSalary' ? (
                                    <Heading
                                        fontSize="2xl"
                                        fontWeight="semibold"
                                        maxW="151px">
                                        <FormattedMessage
                                            {...primoAddBeneficiariesStepMessages.onSalary}
                                        />
                                    </Heading>
                                ) : (
                                    <>
                                        <Heading
                                            fontSize="2xl"
                                            fontWeight="semibold"
                                            lineHeight="7">
                                            {`+ ${truncatedAmount} €`}
                                        </Heading>
                                        <Heading
                                            fontSize="sm"
                                            fontWeight="semibold">
                                            {'/ '}
                                            <FormattedMessage
                                                {...paymentFrequencyMessages.monthly}
                                            />
                                        </Heading>
                                    </>
                                )}
                            </VStack>
                            <VStack>
                                <Heading
                                    color="gray.700"
                                    fontSize="sm"
                                    fontWeight="semibold">
                                    {option?.contributionStructure}
                                </Heading>
                                {option?.pmssCalculation !== null && (
                                    <HStack display="flex" spacing="1">
                                        <>
                                            <Heading
                                                fontSize="xs"
                                                fontWeight="semibold">
                                                <SafeFormattedMessage
                                                    {...primoReinforcingGuaranteesMessages.pmssCalculation}
                                                    values={{
                                                        calculationAmount:
                                                            option
                                                                ?.pmssCalculation
                                                                ?.pmssPercentage,
                                                    }}
                                                    debugKey={`Primo - ReinforcingGuaranteesCard pmssCalculation - ${option?.pmssCalculation?.pmssPercentage}`}
                                                />
                                            </Heading>
                                            <Tooltip
                                                label={formatMessage(
                                                    primoReinforcingGuaranteesMessages.pmssExplanation,
                                                    {
                                                        pmssValue:
                                                            option
                                                                ?.pmssCalculation
                                                                ?.pmssValue,
                                                        currentYear:
                                                            new Date().getFullYear(),
                                                    }
                                                )}
                                                placement="top"
                                                bg="white"
                                                color="black"
                                                hasArrow>
                                                <Box as="span" mt="-1">
                                                    <InfoIcon
                                                        color="black"
                                                        w="4"
                                                        h="4"
                                                    />
                                                </Box>
                                            </Tooltip>
                                        </>
                                    </HStack>
                                )}
                            </VStack>
                        </VStack>
                        <Button
                            colorScheme="primary"
                            bg={
                                isSelected
                                    ? 'primary.ultradark'
                                    : 'primary.main'
                            }
                            size={isMobile ? 'lg' : 'md'}
                            w="fit-content"
                            onClick={() =>
                                setSelectedCard(
                                    selectedCard === option ? null : option
                                )
                            }>
                            <FormattedMessage
                                {...(selectedCard === null
                                    ? coreSharedMessages.choose
                                    : isSelected
                                      ? coreSharedMessages.remove
                                      : coreSharedMessages.replace)}
                            />
                        </Button>
                    </VStack>
                );
            })}
        </Flex>
    );
};

export default ReinforcingGuaranteesCard;
