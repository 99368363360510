import {
    flattenViolations,
    FormValues,
    useFormValidation,
    ViolationMessage,
} from 'core';
import { useToast } from 'design-system/components';
import React from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { defineMessage, useIntl } from 'react-intl';
import { FormModal } from 'core/components/modals/FormModal';
import {
    healthCareBeneficiariesPath,
    useTeleconsultationBeneficiariesChild,
} from 'lib/health-care';

type AddBeneficiaryModalProps = {
    isOpen: boolean;
    onClose: () => void;
};

export const addBeneficiaryModalMessages = {
    successAddBeneficiaryToast: defineMessage({
        id: 'success-message.add-beneficiary',
        defaultMessage:
            '{name} a bien été {gender, select, male {ajouté} female {ajoutée} other {ajouté(e)}}.',
    }),
};

export default function AddBeneficiaryModal({
    isOpen,
    onClose,
}: AddBeneficiaryModalProps) {
    const {
        status,
        data: form,
        error,
        isFetching,
    } = useTeleconsultationBeneficiariesChild();
    const { formatMessage } = useIntl();
    const queryClient = useQueryClient();
    const successToast = useToast();

    const [errors, setErrors] = React.useState<ViolationMessage[]>([]);
    const [submitErrorCode, setSubmitErrorCode] = React.useState<number>(null);

    const { onSubmit, methods, isSubmitting } = useFormValidation(
        'healthcare-access',
        form?.iri,
        form?.widgets
    );

    const handleOnSubmit = async (data: FormValues) => {
        const result = await onSubmit(data);

        if (result.status >= 400 && result.status !== 412) {
            setSubmitErrorCode(result.status);
        } else {
            setSubmitErrorCode(null);
        }

        if (result) {
            switch (result.status) {
                case 201:
                    successToast({
                        status: 'success',
                        description: formatMessage(
                            addBeneficiaryModalMessages.successAddBeneficiaryToast,
                            {
                                name: data?.firstName?.value,
                                gender: data?.gender?.value,
                            }
                        ),
                    });
                    queryClient.invalidateQueries({
                        queryKey: [healthCareBeneficiariesPath],
                        refetchType: 'active',
                    });
                    onClose();
                    break;
                default:
                    const resultData = result?.data?.data || result?.data;
                    if (resultData?.violations) {
                        const violations = flattenViolations(
                            resultData?.violations
                        );
                        setErrors(violations);
                    }
                    break;
            }
        }
    };

    return (
        <FormModal
            isOpen={isOpen}
            onClose={onClose}
            form={form}
            errors={errors}
            onSubmit={handleOnSubmit}
            methods={methods}
            isSubmitting={isSubmitting}
            formStatus={status}
            isFetching={isFetching}
            formError={error}
            submitErrorCode={submitErrorCode}
        />
    );
}
