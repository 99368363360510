import { useEffect, useRef, useState } from 'react';
import {
    Box,
    Flex,
    HStack,
    Stack,
    Text,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    VStack,
    keyframes,
} from '@chakra-ui/react';
import { Heading } from 'core';
import { FormattedMessage, useIntl } from 'react-intl';
import { useWindowBreakpoints } from 'design-system/hooks';
import { paymentFrequencyMessages } from 'lib/contracts';
import { sharedMessages } from 'lib/shared';
import { Tag } from 'design-system/components';
import { MinusIcon, PlusIcon } from 'design-system/icons';
import {
    primoAddBeneficiariesStepMessages,
    primoReinforcingGuaranteesMessages,
} from '../i18n';
import { uniqueId } from 'lodash';

type GuaranteesCalculationRow = {
    label: React.ReactNode;
    amount: number;
    debitMethod: string;
};

export type GuaranteesCalculationDropdownProps = {
    guaranteesCalculationRows: GuaranteesCalculationRow[];
};

const slideOut = keyframes`
    to {
        transform: translateY(100%);
    }
`;

const slideIn = keyframes`
    from {
        transform: translateY(100%);
    }
    to {
        transform: translateY(0%);
    }
`;

const GuaranteesCalculationDropdown = ({
    guaranteesCalculationRows,
}: GuaranteesCalculationDropdownProps) => {
    const { isMobile } = useWindowBreakpoints();
    const { formatMessage } = useIntl();
    const [accordionVisible, setAccordionVisible] = useState(true);
    const stackRef = useRef<HTMLDivElement | null>(null);

    const totalAmount = guaranteesCalculationRows
        .reduce((sum, block) => sum + block.amount, 0)
        .toFixed(2);

    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) => {
            setAccordionVisible(!entry.isIntersecting);
        });

        if (stackRef.current) {
            observer.observe(stackRef.current);
        }

        return () => {
            if (stackRef.current) {
                observer.unobserve(stackRef.current);
            }
        };
    }, []);

    const _renderRow = (
        { label, amount, debitMethod }: GuaranteesCalculationRow,
        borderRadius = '0px'
    ) => (
        <Box
            rounded="md"
            borderColor="strokes.medium"
            borderWidth="1px 1px 0px 1px"
            p="6"
            borderRadius={borderRadius}
            key={String(label)}>
            <Flex
                flexDir={isMobile ? 'column' : 'row'}
                justify={'space-between'}
                gap="6">
                <Stack>
                    <Heading fontSize="sm" fontWeight="semibold">
                        {label}
                    </Heading>
                    <Text fontSize="xs" color="grey.700">
                        <FormattedMessage
                            {...(debitMethod === 'onSalary'
                                ? primoAddBeneficiariesStepMessages.onSalary
                                : primoAddBeneficiariesStepMessages.contributionRIB)}
                        />
                    </Text>
                </Stack>
                {debitMethod !== 'onSalary' && (
                    <HStack color="texts.main">
                        <Heading fontSize="md" fontWeight="semibold">
                            {`${amount.toFixed(2)} €`}
                        </Heading>
                        <Heading fontSize="sm" fontWeight="semibold">
                            {'/ '}
                            <FormattedMessage
                                {...paymentFrequencyMessages.monthly}
                            />
                        </Heading>
                    </HStack>
                )}
            </Flex>
        </Box>
    );

    const _renderAccordionPanel = ({
        label,
        amount,
        debitMethod,
    }: GuaranteesCalculationRow) => (
        <AccordionPanel
            p={5}
            borderWidth="1px 0px 0px 0px"
            borderColor="strokes.medium"
            key={uniqueId()}>
            <VStack color="texts.main" alignItems="start">
                <Heading fontSize="xs" fontWeight="semibold">
                    {label}
                </Heading>
                {debitMethod !== 'onSalary' && (
                    <HStack>
                        <Heading fontSize="sm" fontWeight="semibold">
                            {`${amount.toFixed(2)} €`}
                        </Heading>
                        <Heading fontSize="xs" fontWeight="semibold">
                            {'/ '}
                            <FormattedMessage
                                {...paymentFrequencyMessages.monthly}
                            />
                        </Heading>
                    </HStack>
                )}
            </VStack>
        </AccordionPanel>
    );

    return (
        <>
            {isMobile && (
                <Accordion
                    allowToggle
                    bg="white"
                    w="100vw"
                    position="fixed"
                    left={0}
                    bottom={0}
                    zIndex={20}
                    borderColor="strokes.medium"
                    sx={{
                        animation: accordionVisible
                            ? `${slideIn} 1s forwards`
                            : `${slideOut} 1s forwards`,
                    }}>
                    <AccordionItem>
                        {({ isExpanded }) => (
                            <>
                                <AccordionButton py="6" px="5">
                                    <Box
                                        as="span"
                                        display="flex"
                                        flexDir="row"
                                        flex="1"
                                        textAlign="left"
                                        gap="2">
                                        <Heading
                                            fontSize="xl"
                                            fontWeight="semibold">
                                            <FormattedMessage
                                                {...sharedMessages.total}
                                            />
                                        </Heading>
                                        <HStack color="primary.main">
                                            <Heading
                                                fontSize="xl"
                                                fontWeight="semibold">
                                                {`${totalAmount} €`}
                                            </Heading>
                                            <Heading
                                                fontSize="sm"
                                                fontWeight="semibold">
                                                {'/ '}
                                                <FormattedMessage
                                                    {...paymentFrequencyMessages.monthly}
                                                />
                                            </Heading>
                                        </HStack>
                                    </Box>
                                    <Tag
                                        formattedText={formatMessage(
                                            isExpanded
                                                ? sharedMessages.less
                                                : sharedMessages.details
                                        )}
                                        rightIcon={
                                            isExpanded ? (
                                                <MinusIcon />
                                            ) : (
                                                <PlusIcon />
                                            )
                                        }
                                        bg="primary.50"
                                        color="primary.main"
                                        fontWeight={'bold'}
                                        fontSize={'xs'}
                                    />
                                </AccordionButton>
                                {guaranteesCalculationRows.map(
                                    _renderAccordionPanel
                                )}
                            </>
                        )}
                    </AccordionItem>
                </Accordion>
            )}

            <Stack spacing="0" ref={stackRef}>
                {guaranteesCalculationRows.map((block, index) =>
                    _renderRow(block, index === 0 ? '8px 8px 0px 0px' : '0px')
                )}
                <Box
                    rounded="md"
                    borderColor="strokes.medium"
                    borderWidth="1px 1px 1px 1px"
                    p="6"
                    borderRadius="0px 0px 8px 8px">
                    <Flex
                        flexDir={isMobile ? 'column' : 'row'}
                        justify={'space-between'}
                        gap="6">
                        <Stack>
                            <Heading fontSize="xl" fontWeight="semibold">
                                <FormattedMessage {...sharedMessages.total} />
                            </Heading>
                            <Heading
                                fontSize="sm"
                                color="grey.700"
                                fontWeight="semibold">
                                <FormattedMessage
                                    {...primoReinforcingGuaranteesMessages.atYourCharge}
                                />
                            </Heading>
                        </Stack>
                        <HStack color="primary.main">
                            <Heading fontSize="xl" fontWeight="semibold">
                                {`${totalAmount} €`}
                            </Heading>
                            <Heading fontSize="sm" fontWeight="semibold">
                                {'/ '}
                                <FormattedMessage
                                    {...paymentFrequencyMessages.monthly}
                                />
                            </Heading>
                        </HStack>
                    </Flex>
                </Box>
            </Stack>
        </>
    );
};

export default GuaranteesCalculationDropdown;
