import { useEffect } from 'react';

import { useSessionStorage } from 'core';
import { useAdditionalProducts } from '../api';
import LayoutBoundary from 'components/Layouts/LayoutBoundary';
import { PRIMO_STORAGE_KEY, primoSteps } from 'lib/primo/const';
import { ConditionGuardProps, PrimoStepsEnum } from '../types';

export const ReinforcingGuaranteesGuard = ({
    children,
    primoData,
    setPrimoData,
}: ConditionGuardProps) => {
    const { data: additionalProductsData, status } = useAdditionalProducts();
    const { save } = useSessionStorage();

    const mustSkipReinforcingGuarantees =
        additionalProductsData?.options?.length === 0 &&
        additionalProductsData?.supplementaries?.length === 0;

    const stepNumber = primoSteps.findIndex(
        (step) => step.name === PrimoStepsEnum.REINFORCING_GUARANTEES
    );

    useEffect(() => {
        if (status === 'success' && mustSkipReinforcingGuarantees) {
            const nextData = {
                ...primoData,
                previousStep: stepNumber,
                currentStep:
                    primoData?.previousStep > stepNumber
                        ? stepNumber - 1
                        : stepNumber + 1,
            };
            setPrimoData(nextData);
            save(PRIMO_STORAGE_KEY, nextData);
            window.scrollTo(0, 0);
        }
    }, [mustSkipReinforcingGuarantees]);

    if (mustSkipReinforcingGuarantees) {
        return null;
    }

    return (
        <LayoutBoundary p="0" status={status} data-testid="step-guard-layout">
            {children}
        </LayoutBoundary>
    );
};
