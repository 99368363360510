import React from 'react';
import { useWindowBreakpoints } from 'design-system/hooks';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    CoreModal,
    CoreModalBody,
    CoreModalContent,
    CoreModalFooter,
    CoreModalHeader,
    useToast,
} from 'design-system/components';
import { primoBeneficiariesMessages } from '../i18n';
import { Button, Text } from '@chakra-ui/react';
import { coreSharedMessages, errorToastsMessages } from 'core';
import {
    additionalProductsPath,
    beneficiariesPath,
    useDeleteBeneficiary,
} from '../api';
import { Beneficiary } from '../types';
import { useQueryClient } from '@tanstack/react-query';

type DeleteBeneficiariesModalProps = {
    isOpen: boolean;
    onClose: () => void;
    beneficiary: Beneficiary;
    setBeneficiary: (beneficiary: Beneficiary) => void;
};

export default function DeleteBeneficiariesModal({
    isOpen,
    onClose,
    beneficiary,
    setBeneficiary,
}: DeleteBeneficiariesModalProps) {
    const { formatMessage } = useIntl();
    const queryClient = useQueryClient();
    const { isMobile } = useWindowBreakpoints();
    const { mutate: deleteBeneficiary } = useDeleteBeneficiary();

    const errorToast = useToast({
        status: 'error',
    });

    const successToast = useToast({
        status: 'success',
        description: formatMessage(coreSharedMessages.deletionToast),
    });

    const onDeleteBeneficiary = async () => {
        deleteBeneficiary(
            {
                id: beneficiary.identifier,
            },
            {
                onSuccess: () => {
                    queryClient.invalidateQueries({
                        queryKey: [beneficiariesPath],
                    });
                    queryClient.invalidateQueries({
                        queryKey: [additionalProductsPath],
                    });
                    successToast();
                    onClose();
                },
                onError: (error) => {
                    errorToast({
                        description: formatMessage(
                            errorToastsMessages[error?.code] ||
                                coreSharedMessages.error
                        ),
                    });
                    onClose();
                },
            }
        );
    };

    const onCloseModal = () => {
        setBeneficiary(null);
        onClose();
    };

    return (
        <CoreModal isOpen={isOpen} onClose={onCloseModal} isCentered>
            <CoreModalContent maxW={isMobile ? '100%' : 'xl'} mx="0">
                <CoreModalHeader>
                    <FormattedMessage
                        {...primoBeneficiariesMessages?.beneficiaryDeleteTitle}
                    />
                </CoreModalHeader>
                <CoreModalBody>
                    <Text lineHeight={2}>
                        <FormattedMessage
                            {...primoBeneficiariesMessages.beneficiaryDeleteDescription}
                        />
                    </Text>
                </CoreModalBody>
                <CoreModalFooter>
                    <Button w="full" onClick={onCloseModal}>
                        <FormattedMessage {...coreSharedMessages.cancel} />
                    </Button>
                    <Button
                        colorScheme="primary"
                        onClick={onDeleteBeneficiary}
                        w="full">
                        <FormattedMessage {...coreSharedMessages.delete} />
                    </Button>
                </CoreModalFooter>
            </CoreModalContent>
        </CoreModal>
    );
}
