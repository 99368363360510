export type ContractType = 'health' | 'provident' | 'casuality';
export type ContractStatus =
    | 'ongoing'
    | 'portability'
    | 'future'
    | 'closed'
    | 'nonExisting';
export type SubscriptionType = 'collective' | 'individual' | 'territorial';

export type ContractWithMenu = {
    contract: Contract;
    menu: ContractMenuItem[];
};

export type Contract = {
    type: ContractType;
    subscriptionType: SubscriptionType;
    status: ContractStatus;
    contractId: string;
    membershipNumber: null | string;
    policyId: null | string;
    companyName: null | string;
    companyId: null | string;
    populationType: null | string;
    deregistrationDate: null | string;
    deregistrationReason: null | string;
    affiliationDate: string | null;
};

export type ContractMenuItem = {
    item:
        | 'tpCard'
        | 'products'
        | 'guarantees'
        | 'beneficiaries'
        | 'contributions'
        | 'documents'
        | 'healthProducts';
    isAvailable: boolean;
};

export enum ContractStatusEnum {
    ONGOING = 'ongoing',
    PORTABILITY = 'portability',
    FUTURE = 'future',
    CLOSED = 'closed',
}
