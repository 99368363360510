import { Flex } from '@chakra-ui/react';
import React from 'react';
import { PDFViewer } from 'core/components/document-preview/FileViewer/viewers/PDFViewer';

type PDFViewerProps = {
    mergePdfData?: Uint8Array;
    onDocumentLoad: () => any;
    locale: string;
};

export function SignaturePDFViewer({
    mergePdfData,
    onDocumentLoad,
    locale,
}: PDFViewerProps) {
    return (
        <Flex
            id="pdf-viewer-container"
            justifyContent="center"
            alignItems="center"
            height="80vh"
            w="100%"
            bg="white"
            sx={{
                'div.rpv-core__inner-page': {
                    padding: 0,
                },
            }}>
            {mergePdfData && (
                <PDFViewer
                    onDocumentLoad={onDocumentLoad}
                    file={mergePdfData}
                    locale={locale}
                />
            )}
        </Flex>
    );
}
