import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useWindowBreakpoints } from 'design-system/hooks';
import { FormProvider } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import Alert from 'design-system/components/Alert';
import { primoBeneficiariesMessages } from '../i18n';
import { Button, Stack } from '@chakra-ui/react';
import {
    FormErrors,
    FormLibrarian,
    ViolationMessage,
    WidgetKeys,
    coreErrors,
    coreSharedMessages,
    useFormValidation,
} from 'core';
import {
    additionalProductsPath,
    beneficiariesPath,
    usePostBeneficiaries,
    usePutBeneficiary,
} from '../api';
import { formatBeneficiaryData } from '../helpers';
import { Beneficiary, BeneficiaryType } from '../types';
import {
    useToast,
    useGenericToast,
    CoreModal,
    CoreModalBody,
    CoreModalContent,
    CoreModalFooter,
    CoreModalHeader,
} from 'design-system/components';
import { addBeneficiaryModalMessages } from 'components/Teleconsultation/AddBeneficiaryModal';
import {
    formBeneficiaryChildInformations,
    formBeneficiaryChoice,
    formBeneficiaryPartnerInformations,
} from '../const';
import { useQueryClient } from '@tanstack/react-query';

type AddBeneficiariesModalProps = {
    isOpen: boolean;
    onClose: () => void;
    beneficiaryEdit?: Beneficiary;
    setBeneficiaryEdit?: (beneficiary: Beneficiary) => void;
    hasMoreThan24Children: boolean;
    hasAlreadyPartner: boolean;
};

const TOAST_ERROR_CODES = [400, 404, 500];

export default function AddBeneficiariesModal({
    isOpen,
    onClose,
    beneficiaryEdit = null,
    setBeneficiaryEdit,
    hasMoreThan24Children,
    hasAlreadyPartner,
}: AddBeneficiariesModalProps) {
    const { formatMessage } = useIntl();
    const queryClient = useQueryClient();
    const { isMobile } = useWindowBreakpoints();
    const bodyRef = useRef(null);
    const [beneficiaryType, setBeneficiaryType] =
        useState<BeneficiaryType>(null);
    const [errors, setErrors] = useState<ViolationMessage[]>([]);
    const { mutate: postBeneficiaries } = usePostBeneficiaries();
    const { mutate: updateBeneficiary } = usePutBeneficiary();
    const showToast = useGenericToast();
    useEffect(() => {
        if (!!beneficiaryEdit) {
            setBeneficiaryType(beneficiaryEdit.beneficiaryType);
        } else {
            setBeneficiaryType(null);
            methods.reset();
        }
    }, [beneficiaryEdit, isOpen]);

    function getDefaultValuesFromBeneficiary(
        beneficiaryEdit: Beneficiary
    ): Partial<Beneficiary> {
        return {
            beneficiaryType: beneficiaryEdit.beneficiaryType || null,
            identifier: beneficiaryEdit.identifier,
            gender: beneficiaryEdit.gender || null,
            lastName: beneficiaryEdit.lastName || '',
            firstName: beneficiaryEdit.firstName || '',
            birthDate: beneficiaryEdit.birthDate || '',
            socialSecurityNumberDetails: {
                ownSocialSecurityNumber:
                    beneficiaryEdit.socialSecurityNumberDetails
                        ?.ownSocialSecurityNumber || '',
                socialSecurityConnection:
                    beneficiaryEdit.socialSecurityNumberDetails
                        ?.socialSecurityConnection || null,
                attachedSocialSecurityNumber:
                    beneficiaryEdit.socialSecurityNumberDetails
                        ?.attachedSocialSecurityNumber || null,
            },
            otherComplementaryHealthInsurance:
                beneficiaryEdit.otherComplementaryHealthInsurance
                    ? true
                    : false,
            organizationCode: beneficiaryEdit.organizationCode || '',
            localRegime: beneficiaryEdit.localRegime || false,
        };
    }

    const form = useMemo(() => {
        const defaultValues: Partial<Beneficiary> = beneficiaryEdit
            ? getDefaultValuesFromBeneficiary(beneficiaryEdit)
            : null;

        if (!beneficiaryType) {
            return formBeneficiaryChoice({
                hasMoreThan24Children,
                hasAlreadyPartner,
                defaultValues,
            });
        }

        if (beneficiaryType === BeneficiaryType.PARTNER) {
            return formBeneficiaryPartnerInformations(defaultValues);
        }

        return formBeneficiaryChildInformations(defaultValues);
    }, [
        beneficiaryEdit,
        beneficiaryType,
        hasMoreThan24Children,
        hasAlreadyPartner,
    ]);

    const { methods } = useFormValidation(
        null,
        form.iri,
        form.widgets as WidgetKeys[]
    );

    const errorToast = useToast({
        status: 'error',
        description: formatMessage(coreErrors.serviceUnavailable),
    });

    const modificiationSuccessToast = useToast({
        status: 'success',
        description: formatMessage(coreSharedMessages.confirmationToast),
    });

    const handleError = (error) => {
        if (
            TOAST_ERROR_CODES.includes(error?.code) ||
            error?.errors?.length === 0
        ) {
            errorToast();
            onClose();
        } else {
            setErrors(error.errors);
        }
    };

    async function onSubmitForm(data) {
        setErrors([]);
        if (beneficiaryType === null) {
            setBeneficiaryType(data?.beneficiary?.type?.title?.value);
            methods.reset();
        } else if (!!beneficiaryEdit) {
            updateBeneficiary(
                {
                    id: beneficiaryEdit.identifier,
                    data: formatBeneficiaryData(data, beneficiaryType),
                },
                {
                    onSuccess: () => {
                        queryClient.invalidateQueries({
                            queryKey: [beneficiariesPath],
                        });
                        queryClient.invalidateQueries({
                            queryKey: [additionalProductsPath],
                        });
                        modificiationSuccessToast();
                        onCloseModal();
                    },
                    onError: handleError,
                }
            );
        } else {
            postBeneficiaries(formatBeneficiaryData(data, beneficiaryType), {
                onSuccess: () => {
                    queryClient.invalidateQueries({
                        queryKey: [beneficiariesPath],
                    });
                    queryClient.invalidateQueries({
                        queryKey: [additionalProductsPath],
                    });
                    showToast(
                        addBeneficiaryModalMessages?.successAddBeneficiaryToast,
                        {
                            name: data?.firstName?.value,
                            gender: data?.gender?.value,
                        }
                    );
                    onCloseModal();
                },
                onError: handleError,
            });
        }
    }

    const onCloseModal = () => {
        setBeneficiaryType(null);
        setBeneficiaryEdit(null);
        methods.reset();
        onClose();
    };

    useEffect(() => {
        if (errors.length) {
            bodyRef?.current?.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
            });
        }
    }, [errors]);

    const _renderFormBeneficiary = (): JSX.Element => (
        <Stack spacing="6">
            {beneficiaryType === null && !hasAlreadyPartner && (
                <Alert
                    displayedTitle={formatMessage(
                        primoBeneficiariesMessages.beneficiaryAddPartnerInfo
                    )}
                    icon="InfoIcon"
                    color="info"
                    my="0"
                />
            )}
            <>
                {errors?.length > 0 && <FormErrors errors={errors} />}
                {form?.widgets &&
                    form.widgets.map(({ type, id, ...props }) => (
                        <FormLibrarian
                            key={id}
                            type={type}
                            props={{
                                id,
                                methods: {
                                    setError: methods.setError,
                                    errors: methods.formState.errors,
                                    clearErrors: methods.clearErrors,
                                },
                                ...props,
                            }}
                        />
                    ))}
            </>
            {beneficiaryType !== null && (
                <Alert
                    displayedTitle={formatMessage(
                        beneficiaryType === BeneficiaryType.PARTNER
                            ? primoBeneficiariesMessages.teletransmissionPartnerInfo
                            : primoBeneficiariesMessages.teletransmissionChildInfo
                    )}
                    icon="InfoIcon"
                    color="info"
                />
            )}
        </Stack>
    );

    return (
        <CoreModal isOpen={isOpen} onClose={onCloseModal} isCentered>
            <FormProvider {...methods}>
                <Stack
                    noValidate
                    as="form"
                    onSubmit={methods.handleSubmit(onSubmitForm)}
                    spacing="6"
                    alignItems={isMobile ? 'stretch' : 'flex-end'}>
                    <CoreModalContent maxW={isMobile ? '100%' : 'xl'} mx="0">
                        <CoreModalHeader>
                            <FormattedMessage
                                {...(!!beneficiaryEdit
                                    ? primoBeneficiariesMessages?.beneficiaryChangeTitle
                                    : primoBeneficiariesMessages?.beneficiaryAddTitle)}
                            />
                        </CoreModalHeader>
                        <CoreModalBody ref={bodyRef}>
                            {_renderFormBeneficiary()}
                        </CoreModalBody>
                        <CoreModalFooter>
                            <Button w="full" onClick={onCloseModal}>
                                <FormattedMessage
                                    {...coreSharedMessages.cancel}
                                />
                            </Button>
                            <Button
                                colorScheme="primary"
                                type="submit"
                                w="full">
                                <FormattedMessage
                                    {...(beneficiaryType !== null
                                        ? coreSharedMessages.validate
                                        : coreSharedMessages.next)}
                                />
                            </Button>
                        </CoreModalFooter>
                    </CoreModalContent>
                </Stack>
            </FormProvider>
        </CoreModal>
    );
}
