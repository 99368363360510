import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { APIError, FetchQueryParams } from 'core';
import { SignatureFile } from 'core/lib/signature';

export const signatureSepaFilesPath = `/api/proxy/primo/signature/sepa/files`;
export const signatureSepaPhonePath = `/api/proxy/primo/signature/sepa/phone`;
export const signatureSepaCodePath = `/api/proxy/primo/signature/sepa/code`;
export const resendSmsPath = `/api/proxy/primo/signature/sepa/resend-sms`;

export async function fetchSignatureSepaFile({
    queryKey: [urn],
}: FetchQueryParams) {
    try {
        const res = await axios.get(urn);
        return res?.data?.data?.files;
    } catch (error) {
        throw {
            code: error.response?.status,
        };
    }
}

export function useSignatureSepaFile(enabled: boolean = true) {
    return useQuery<SignatureFile[], APIError>({
        queryKey: [signatureSepaFilesPath],
        queryFn: fetchSignatureSepaFile,
        enabled,
        gcTime: 0, // Disable cache
    });
}

async function postSignatureSepaPhone(data) {
    try {
        await axios.post(signatureSepaPhonePath, data);
    } catch (error) {
        throw {
            code: error.response?.status,
            errors: error.response?.data?.errors,
        };
    }
}

export function usePostSignatureSepaPhone() {
    return useMutation<void, APIError, unknown>({
        mutationFn: postSignatureSepaPhone,
    });
}

async function postSignatureSepaCode(data) {
    try {
        await axios.post(signatureSepaCodePath, data);
    } catch (error) {
        throw {
            code: error.response?.status,
            errors: error.response?.data?.errors,
        };
    }
}

export function usePostSignatureSepaCode() {
    return useMutation<void, APIError, unknown>({
        mutationFn: postSignatureSepaCode,
    });
}

async function postResendSms() {
    try {
        await axios.post(resendSmsPath, {});
    } catch (error) {
        throw {
            code: error.response?.status,
            errors: error.response?.data?.errors,
        };
    }
}

export function usePostResendSms() {
    return useMutation<void, APIError, unknown>({
        mutationFn: postResendSms,
    });
}
