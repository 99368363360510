import { useContext, useState } from 'react';
import {
    Container,
    Stack,
    Text,
    VStack,
    Button,
    LinkOverlay,
    LinkBox,
    Img,
    Box,
} from '@chakra-ui/react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    AppStoreDownloadIllustration,
    GooglePlayDownloadIllustration,
    SuccessIllustration,
} from 'components/Illustrations';
import { primoConfirmationStepMessage } from '../i18n';
import Alert from 'design-system/components/Alert';
import { useDocuments } from '../api/useDocuments';
import LayoutBoundary from 'components/Layouts/LayoutBoundary';
import {
    coreErrors,
    coreSharedMessages,
    FileDownloadCard,
    formatPhoneNumber,
    Heading,
    LanguageContext,
    useRedirect,
    useUserAgent,
} from 'core';
import { siteConfig } from 'lib/site';
import { ArrowRightIcon } from 'design-system/icons';
import {
    getAppStoreLink,
    getPlayStoreLink,
    getWebAppStoreLink,
    getWebPlayStoreLink,
} from 'lib/site/helpers';
import { useWindowBreakpoints } from 'design-system/hooks';
import { useLogout } from 'lib/auth';
import { StepComponentProps } from 'lib/form-wizard/types';
import { useUsername, useContactCenter } from '../api';
import { sharedMessages } from 'lib/shared';

const ConfirmationStep = ({ resetStep }: StepComponentProps) => {
    const { formatMessage } = useIntl();
    const { data, status, error } = useDocuments();
    const { isMobile } = useWindowBreakpoints();
    const { logout } = useLogout();
    const { redirect } = useRedirect();
    const [isRedirecting, setIsRedirecting] = useState<boolean>(false);
    const { locale } = useContext(LanguageContext);
    const { isIOS, isAndroid } = useUserAgent();
    const { data: usernameData } = useUsername(false);
    const { data: contactCenter } = useContactCenter();

    const documents = data || [];
    const { displayName, appName } = siteConfig;

    const redirectToLogin = async () => {
        setIsRedirecting(true);
        await logout('primo', 'primo-auth-token');
        redirect({
            pathname: '/login',
            ...(usernameData?.username
                ? { query: { username: usernameData.username } }
                : {}),
        });
        setIsRedirecting(false);
    };

    return (
        <Container pt="12" mb="10">
            <LayoutBoundary
                // The error is handled inside the component
                status={status === 'error' ? 'success' : status}
                px={0}
                py={0}
                errorButtons={[
                    {
                        text: coreSharedMessages.buttonBackHome,
                        onClick: resetStep,
                    },
                ]}
                messages={{
                    error: coreErrors.unavailableContentTitle,
                    errorDescription: coreErrors.unavailableContentText,
                }}>
                <Stack
                    flexDirection={isMobile ? 'column-reverse' : 'row'}
                    bg="bg.extralight"
                    borderRadius="lg"
                    p="6"
                    mt="6"
                    gap={isMobile ? '10' : '4'}>
                    <Stack gap="4" flexShrink={1}>
                        <Heading lineHeight="1.25em" as="h2">
                            <FormattedMessage
                                {...primoConfirmationStepMessage.title}
                            />
                        </Heading>
                        <Text>
                            <FormattedMessage
                                {...primoConfirmationStepMessage.description}
                                values={{ appName }}
                            />
                        </Text>
                    </Stack>
                    <SuccessIllustration
                        {...(isMobile
                            ? { width: 'full', height: '123px' }
                            : { height: 'auto' })}
                    />
                </Stack>

                <Stack mt="9">
                    <Heading lineHeight="1.25em" as="h2">
                        <FormattedMessage
                            {...primoConfirmationStepMessage.documentsListTitle}
                            values={{ nbDocuments: documents.length }}
                        />
                    </Heading>

                    {!!error ? (
                        <Alert
                            color="error"
                            icon="WarningIcon"
                            description={
                                <>
                                    <FormattedMessage
                                        {...primoConfirmationStepMessage.missingDataTitle}
                                    />
                                    <Box mt="3">
                                        <FormattedMessage
                                            {...primoConfirmationStepMessage.missingDataDescription}
                                            values={{
                                                phoneNumber:
                                                    contactCenter?.phoneNumber
                                                        ? formatPhoneNumber.loose(
                                                              contactCenter.phoneNumber
                                                          )
                                                        : undefined,
                                            }}
                                        />
                                    </Box>
                                </>
                            }
                            mb="0"
                        />
                    ) : (
                        <>
                            <Alert
                                color="info"
                                icon="InfoIcon"
                                description={formatMessage(
                                    primoConfirmationStepMessage.documentsListDescription,
                                    { nbDocuments: documents.length }
                                )}
                                descriptionProps={{
                                    fontWeight: 'bold',
                                }}
                            />

                            <Stack gap="6">
                                {documents.map((doc, index) => (
                                    <FileDownloadCard
                                        key={index}
                                        type={doc.type}
                                        name={doc.name}
                                        source="primo"
                                        id={doc.url}
                                        date={doc.date}
                                    />
                                ))}
                            </Stack>
                        </>
                    )}

                    <VStack
                        gap="2"
                        mt="16"
                        bg="bg.extralight"
                        p="6"
                        borderRadius="lg">
                        <Img
                            src="/images/logos/client-app.svg"
                            htmlWidth="60px"
                            mb="2"
                        />
                        <Heading lineHeight="1.25em" as="h2">
                            {displayName}
                        </Heading>
                        <Text textAlign="center">
                            <FormattedMessage
                                {...primoConfirmationStepMessage.storeDownloadDescription}
                                values={{ name: displayName }}
                            />
                        </Text>
                        <Stack
                            gap="4"
                            mt="4"
                            flexDirection={isMobile ? 'column' : 'row'}
                            alignItems="center">
                            <LinkBox>
                                <LinkOverlay
                                    target="_blank"
                                    rel="noreferrer"
                                    href={
                                        isIOS
                                            ? getAppStoreLink(locale)
                                            : getWebAppStoreLink(locale)
                                    }>
                                    <AppStoreDownloadIllustration />
                                </LinkOverlay>
                            </LinkBox>
                            <LinkBox>
                                <LinkOverlay
                                    target="_blank"
                                    rel="noreferrer"
                                    href={
                                        isAndroid
                                            ? getPlayStoreLink()
                                            : getWebPlayStoreLink()
                                    }>
                                    <GooglePlayDownloadIllustration />
                                </LinkOverlay>
                            </LinkBox>
                        </Stack>
                    </VStack>

                    <Button
                        colorScheme="primary"
                        rightIcon={<ArrowRightIcon fill="white" />}
                        borderRadius="99px"
                        margin="auto"
                        mt="14"
                        onClick={redirectToLogin}
                        isLoading={isRedirecting}>
                        <FormattedMessage {...sharedMessages.end} />
                    </Button>
                </Stack>
            </LayoutBoundary>
        </Container>
    );
};

export default ConfirmationStep;
