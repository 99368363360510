import axios from 'axios';
import { APIError, FetchQueryParams } from 'core';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Beneficiary } from '../types';

export const beneficiariesPath = `/api/proxy/primo/beneficiaries`;

async function fetchBeneficiaries({ queryKey: [urn] }: FetchQueryParams) {
    try {
        const res = await axios.get(urn);

        return res.data.data;
    } catch (error) {
        throw {
            code: error.response?.status,
        };
    }
}

export function useBeneficiaries(enabled: boolean = true) {
    return useQuery<Beneficiary[], APIError>({
        queryKey: [beneficiariesPath],
        queryFn: fetchBeneficiaries,
        enabled,
    });
}

async function postBeneficiaries(data: Beneficiary[]) {
    try {
        await axios.post(beneficiariesPath, data);
    } catch (error) {
        throw {
            code: error.response?.status,
            errors: error.response?.data?.errors,
        };
    }
}

export function usePostBeneficiaries() {
    return useMutation<void, APIError, unknown>({
        mutationFn: postBeneficiaries,
    });
}

async function updateBeneficiary(id: number, data: Beneficiary) {
    try {
        const url = `${beneficiariesPath}/${id}`;
        await axios.put(url, data);
    } catch (error) {
        throw {
            code: error.response?.status,
            errors: error.response?.data?.errors,
        };
    }
}

export function usePutBeneficiary() {
    return useMutation<void, APIError, unknown>({
        mutationFn: ({ id, data }) => updateBeneficiary(id, data),
    });
}

async function deleteBeneficiary(id: number) {
    try {
        const url = `${beneficiariesPath}/${id}`;
        await axios.delete(url);
    } catch (error) {
        throw {
            code: error.response?.status,
            errors: error.response?.data?.errors,
        };
    }
}

export function useDeleteBeneficiary() {
    return useMutation<void, APIError, unknown>({
        mutationFn: ({ id }) => deleteBeneficiary(id),
    });
}
