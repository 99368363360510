import React from 'react';
import { defineMessage, useIntl } from 'react-intl';
import { NumberFormatProps } from 'react-number-format';
import {
    Input,
    InputGroup,
    InputProps,
    InputRightElement,
    NumberInput,
    Button,
    forwardRef,
    HStack,
} from '@chakra-ui/react';

import { useSafeIntl, WidgetType, formMessages, FormGroup } from 'core';
import { EyeCloseIcon, EyeOpenIcon } from 'design-system/icons';
import { WidgetProps } from './widgets/types';
import getInputName from 'core/lib/forms/helpers/getInputName';

type InputType = 'text' | 'email' | 'number' | 'password' | 'tel';

type InputExternalProps = Partial<InputProps> &
    Partial<Omit<NumberFormatProps, 'type'>>;

type InputBaseProps = InputExternalProps &
    WidgetProps & {
        ref: React.Ref<HTMLInputElement>;
        type?: InputType;
        showErrors?: boolean;
        onChange?: (e: any) => void;
        [x: string]: any;
    };

const startsWithZero = (value: string) => value.startsWith('0');
const parseIntNumber = (value: string) => {
    if (startsWithZero(value)) return value.substring(1).concat('.', '00');
    return value.concat('.', '00');
};

const parseFloatNumber = (value: string, precision: number) => {
    const regex = new RegExp(`^-?\\d+(?:.\\d{0,${precision}})?`);
    if (value.toString().split('.')[1].length === 1)
        return value.toString().concat('0');
    if (startsWithZero(value)) {
        return value
            .split('.')[0]
            .substring(1)
            .concat('.', value.split('.')[1]);
    }
    return value.toString().match(regex)[0];
};

const manageInputNumber = (value: string, precision?: number) => {
    if (!value.includes('.')) return parseIntNumber(value);
    return parseFloatNumber(value, precision);
};

const InputNumber = forwardRef(({ precision = 2, _, ...inputProps }, ref) => (
    <NumberInput precision={precision} w="full">
        <Input
            ref={ref}
            {...inputProps}
            inputMode="decimal"
            onWheel={(e) => {
                e.currentTarget.blur();
            }}
            onBlur={(e) => {
                if (e.target.value.length) {
                    e.target.value = manageInputNumber(
                        e.currentTarget.value,
                        precision
                    );
                    inputProps.onChange(e);
                }
                inputProps.onBlur(e);
            }}
            type="number"
        />
    </NumberInput>
));

const intlMessages = defineMessage({
    id: 'components.forms.input-base.input-password-icon',
    defaultMessage: 'Bouton afficher/masquer le mot de passe',
    description: "Label du bouton d'aide du bouton pour les screens reader.",
});

const InputPassword = forwardRef((props, ref) => {
    const [switchType, setSwitchType] = React.useState<WidgetType>(
        () => props.type
    );
    const { formatMessage } = useIntl();

    return (
        <InputGroup>
            <Input ref={ref} {...props} type={switchType} />
            <InputRightElement h="full">
                <Button
                    aria-label={formatMessage(intlMessages)}
                    variant="inputRight"
                    onClick={(event) => {
                        event.preventDefault();
                        if (switchType === 'password') setSwitchType('text');
                        else setSwitchType(props.type);
                    }}>
                    {switchType === 'password' ? (
                        <EyeOpenIcon />
                    ) : (
                        <EyeCloseIcon />
                    )}
                </Button>
            </InputRightElement>
        </InputGroup>
    );
});

const InputText = forwardRef((props, ref) => {
    const { type, isReadOnly } = props;
    return type === 'password' ? (
        <InputPassword ref={ref} {...props} type={type} />
    ) : (
        <Input
            {...props}
            autoComplete="none"
            ref={ref}
            isReadOnly={isReadOnly}
            tabIndex={isReadOnly ? -1 : 0}
        />
    );
});

function InputBase(
    {
        id,
        title,
        placeholder,
        description,
        links,
        name,
        help,
        hideLabel,
        readonly,
        defaultValue,
        validations,
        type = 'text',
        showErrors = true,
        children,
        ...rest
    }: React.PropsWithChildren<InputBaseProps>,
    ref: React.Ref<HTMLInputElement>
) {
    const { safeFormatMessage } = useSafeIntl();
    const inputName = getInputName(id, name);

    const inputProps = {
        name: inputName,
        placeholder:
            placeholder &&
            safeFormatMessage(formMessages[placeholder], null, placeholder),
        defaultValue,
        ref,
        type,
        ...rest,
    };

    return (
        <FormGroup
            showErrors={readonly ? false : showErrors}
            label={title}
            name={inputName}
            isRequired={validations?.required}
            isReadOnly={readonly}
            {...{ id, description, hideLabel, help, links }}>
            <HStack>
                {children}
                {type === 'number' ? (
                    <InputNumber {...inputProps} />
                ) : (
                    <InputText {...inputProps} isReadOnly={readonly} />
                )}
            </HStack>
        </FormGroup>
    );
}

export default React.forwardRef(InputBase);
