import { FormattedMessage } from 'react-intl';
import { Text } from '@chakra-ui/react';
import { primoSocialSecurityStepMessages } from '../i18n';

const SocialSecurityStepDescription = () => (
    <Text textAlign="left" fontSize="md" lineHeight="5" color="black" mb="3">
        <FormattedMessage
            {...primoSocialSecurityStepMessages.socialSecurityDescription}
        />
    </Text>
);

export default SocialSecurityStepDescription;
