import {
    primoWelcomeMessages,
    primoPersonalDataStepMessages,
    primoSocialSecurityStepMessages,
    primoAddBeneficiariesStepMessages,
    primoBankDetailsStepMessages,
    primoReinforcingGuaranteesMessages,
    primoSepaMandateStepMessages,
    primoAccessCreationStepMessage,
} from '../i18n';
import Welcome from '../components/Welcome';
import { WizardType, Step } from 'lib/form-wizard/types';
import FormContainer from '../components/FormWizard/FormContainer';
import { PrimoStepsEnum, PrimoContextData } from '../types';
import SocialSecurityStepDescription from '../components/SocialSecurityStepDescription';
import SocialSecurityStepAlert from '../components/SocialSecurityStepAlert';
import AddBeneficiaries from '../components/AddBeneficiaries';
import BankDetailsStep from '../components/BankDetailsStep';
import ReinforcingGuarantees from '../components/ReinforcingGuarantees';
import { ReinforcingGuaranteesGuard } from '../components/ReinforcingGuaranteesGuard';
import VerifySepaMandateStep from '../components/VerifySepaMandateStep';
import CodeVericationStepDescription from '../components/CodeVericationStepDescription';
import DigitalSignatureStep from '../components/DigitalSignatureStep';
import ConfirmationStep from '../components/ConfirmationStep';
import { sharedMessages } from 'lib/shared/i18n/common';
import NewUsername from '../components/NewUsername';
import { signatureSubtitles } from 'core/lib/signature';

export const PRIMO_CONTEXT_DEFAULT_DATA: PrimoContextData = {
    currentStep: 0,
    signaturePhoneNumber: null,
};

export const PRIMO_STORAGE_KEY = 'primo';

const getHeaderProps = (
    currentStep: Step['headerProps']['progressBar']['currentStep']
): Step['headerProps'] => ({
    backgroundColor: 'transparent',
    subtitleColor: 'primary.main',
    progressBar: {
        maxStep: 1,
        currentStep,
        isHeader: false,
    },
});

export const primoSteps: Step[] = [
    {
        name: PrimoStepsEnum.WELCOME,
        Component: Welcome,
        title: primoWelcomeMessages.welcomeTitle,
        hideHeader: true,
    },
    {
        name: PrimoStepsEnum.ADD_BENEFICIARIES,
        Component: AddBeneficiaries,
        headerData: {
            title: primoAddBeneficiariesStepMessages.addBeneficiariesStepTitle,
            subtitle: primoAddBeneficiariesStepMessages.addBeneficiariesTitle,
        },
        headerProps: getHeaderProps(0.4),
    },
    {
        name: PrimoStepsEnum.REINFORCING_GUARANTEES,
        Component: ReinforcingGuarantees,
        headerData: {
            title: primoAddBeneficiariesStepMessages.addBeneficiariesStepTitle,
            subtitle:
                primoReinforcingGuaranteesMessages.reinforcingGuaranteesTitle,
        },
        headerProps: getHeaderProps(0.4),
        ConditionGuard: ReinforcingGuaranteesGuard,
    },
    {
        name: PrimoStepsEnum.INFORMATIONS,
        Component: FormContainer,
        componentData: {
            wizardType: WizardType.PRIMO,
            formType: PrimoStepsEnum.INFORMATIONS,
        },
        headerData: {
            title: primoPersonalDataStepMessages.personalDataStepTitle,
            subtitle: primoPersonalDataStepMessages.personalDataStepSubtitle,
        },
        headerProps: getHeaderProps(0.4),
    },
    {
        name: PrimoStepsEnum.SOCIAL_SECURITY,
        Component: FormContainer,
        componentData: {
            wizardType: WizardType.PRIMO,
            formType: PrimoStepsEnum.SOCIAL_SECURITY,
            topFormComponent: () => <SocialSecurityStepDescription />,
            bottomFormComponent: () => <SocialSecurityStepAlert />,
        },
        headerData: {
            title: primoPersonalDataStepMessages.personalDataStepTitle,
            subtitle: primoSocialSecurityStepMessages.socialSecurityTitle,
        },
        headerProps: getHeaderProps(0.5),
    },
    {
        name: PrimoStepsEnum.BANK_DETAILS,
        Component: BankDetailsStep,
        headerData: {
            title: primoPersonalDataStepMessages.personalDataStepTitle,
            subtitle: primoBankDetailsStepMessages.bankDetailsTitle,
        },
        headerProps: getHeaderProps(0.6),
    },
    {
        name: PrimoStepsEnum.VERIFY_SEPA_MANDATE,
        Component: VerifySepaMandateStep,
        headerData: {
            title: primoSepaMandateStepMessages.sepaMandateStepTitle,
            subtitle: primoSepaMandateStepMessages.sepaMandateVerifyTitle,
        },
        headerProps: getHeaderProps(0.7),
    },
    {
        name: PrimoStepsEnum.SEND_CODE_VERIFICATION_SEPA_MANDATE,
        Component: FormContainer,
        componentData: {
            wizardType: WizardType.PRIMO,
            formType: PrimoStepsEnum.SEND_CODE_VERIFICATION_SEPA_MANDATE,
            topFormComponent: () => <CodeVericationStepDescription />,
            nextButtonMessage:
                primoSepaMandateStepMessages.sepaMandateSendCodeVerification,
            cancelButtonMessage: sharedMessages.cancel,
            cancelRedirectStep: 5, // Bank details step
        },
        headerData: {
            title: primoSepaMandateStepMessages.sepaMandateStepTitle,
            subtitle:
                primoSepaMandateStepMessages.sepaMandateCodeVerificationTitle,
        },
        headerProps: getHeaderProps(0.7),
        hideBreadcrumbs: true,
    },
    {
        name: PrimoStepsEnum.DIGITAL_SIGNATURE,
        Component: DigitalSignatureStep,
        headerData: {
            title: primoSepaMandateStepMessages.sepaMandateStepTitle,
            subtitle: signatureSubtitles['verification-code'],
        },
        headerProps: getHeaderProps(0.7),
        hideBreadcrumbs: true,
    },
    {
        name: PrimoStepsEnum.ACCESS_CREATION,
        Component: FormContainer,
        componentData: {
            wizardType: WizardType.PRIMO,
            formType: PrimoStepsEnum.ACCESS_CREATION,
            topFormComponent: ({ handleError }) => (
                <NewUsername handleError={handleError} />
            ),
            nextButtonMessage: sharedMessages.confirmSubscription,
        },
        headerData: {
            title: primoAccessCreationStepMessage.personalSpace,
            subtitle: primoAccessCreationStepMessage.title,
        },
        headerProps: getHeaderProps(0.9),
    },
    {
        name: PrimoStepsEnum.CONFIRMATION,
        Component: ConfirmationStep,
        hideHeader: true,
        hideBreadcrumbs: true,
    },
];
