import { useState, useEffect } from 'react';
import { Button, Stack, HStack, useDisclosure } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import {
    FormValues,
    Heading,
    defaultFormValidationModes,
    __DEV__,
    WidgetKeys,
    EnhancedQueryStatus,
    coreSharedMessages,
    coreErrors,
    ViolationMessage,
    FormErrors,
} from 'core';
import { useWindowBreakpoints } from 'design-system/hooks';
import { FormattedMessage } from 'react-intl';
import { sharedMessages } from 'lib/shared';
import { FormLibrarian } from 'components/forms';
import { StepComponentProps } from 'lib/form-wizard/types';
import { DevTool } from '@hookform/devtools';
import { usePrefillForm } from 'lib/primo/hooks';
import LayoutBoundary from 'components/Layouts/LayoutBoundary';
import { PrimoStepsEnum } from 'lib/primo/types';
import {
    formatGeneralRegime,
    formatPersonalData,
    formatBeneficiaryBankDetails,
    formatSignatureSepaPhone,
    // formatAccessCreationData,
} from 'lib/primo/helpers';
import {
    usePostPersonalData,
    usePostGeneralRegime,
    usePostBeneficiaryBankDetails,
    usePostSignatureSepaPhone,
    // usePostValidation,
} from 'lib/primo/api';
import { InternationalPhoneNumberProps } from 'core/components/forms/widgets/InternationalPhoneNumber';

const LAYOUT_MESSAGES = {
    error: coreErrors.serviceUnavailableDescription,
    errorDescription: coreErrors.serviceUnavailable,
};

const LAYOUT_ERROR_CODES = [400, 403, 404, 500];

export default function FormContainer({
    handleNextStep,
    title,
    formType,
    bottomFormComponent,
    topFormComponent,
    postSuccessModalComponent,
    additionalData,
    resetStep,
    nextButtonMessage = sharedMessages.next,
    cancelButtonMessage,
    cancelRedirectStep,
}: StepComponentProps) {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [hasLayoutError, setHasLayoutError] = useState<boolean>(false);
    const [validationErrors, setValidationErrors] = useState<
        ViolationMessage[]
    >([]);
    const [prefilledFormWidgets, setPrefilledFormWidgets] = useState<
        (WidgetKeys | InternationalPhoneNumberProps)[]
    >([]);

    const { isMobile } = useWindowBreakpoints();
    const methods = useForm(defaultFormValidationModes);
    const {
        setError,
        formState: { errors },
        clearErrors,
    } = methods;
    const {
        isOpen: isPostSuccessModalOpen,
        onOpen: onPostSuccessModalOpen,
        onClose: onPostModalClose,
    } = useDisclosure();

    const { mutate: postPersonalData } = usePostPersonalData();
    const { mutate: postGeneralRegime } = usePostGeneralRegime();
    const { mutate: postBeneficiaryBankDetails } =
        usePostBeneficiaryBankDetails();
    const { mutate: postSignatureSepaPhone } = usePostSignatureSepaPhone();
    // const { mutate: postValidation } = usePostValidation();

    const prefillForm = usePrefillForm(formType, additionalData);

    const handleError = (error) => {
        if (LAYOUT_ERROR_CODES.includes(error?.code)) {
            setHasLayoutError(true);
        } else {
            setValidationErrors(error?.errors || []);
            window.scrollTo(0, 0);
            setIsSubmitting(false);
        }
    };

    const goToNextStep = (data?: FormValues) => {
        // `handleNextStep` should be called inside a callback, otherwise the FormContainer is not unmounted properly
        setTimeout(() => {
            handleNextStep(data);
        }, 10);
    };

    const handleSuccess = (data?: FormValues) => {
        setIsSubmitting(false);
        goToNextStep(data);
    };

    async function handleSubmitForm(data: FormValues) {
        setValidationErrors([]);
        setIsSubmitting(true);
        switch (formType) {
            case PrimoStepsEnum.INFORMATIONS:
                postPersonalData(formatPersonalData(data), {
                    onSuccess: () => handleSuccess(),
                    onError: handleError,
                });
                break;
            case PrimoStepsEnum.SOCIAL_SECURITY:
                postGeneralRegime(formatGeneralRegime(data), {
                    onSuccess: () => handleSuccess(),
                    onError: handleError,
                });
                break;
            case PrimoStepsEnum.BANK_DETAILS:
                postBeneficiaryBankDetails(
                    {
                        bankDetails: formatBeneficiaryBankDetails(data),
                    },
                    {
                        onSuccess: (data) => {
                            if (
                                data?.meta?.next_step ===
                                'signature_sepa_mandate'
                            ) {
                                setIsSubmitting(false);
                                onPostSuccessModalOpen();
                            } else {
                                handleSuccess();
                            }
                        },
                        onError: handleError,
                    }
                );
                break;
            case PrimoStepsEnum.SEND_CODE_VERIFICATION_SEPA_MANDATE:
                postSignatureSepaPhone(formatSignatureSepaPhone(data), {
                    onSuccess: () => handleSuccess(data),
                    onError: handleError,
                });
                break;
            case PrimoStepsEnum.ACCESS_CREATION:
                // @TODO: Temporary avoid sending request
                handleSuccess();
                // postValidation(formatAccessCreationData(data), {
                //     onSuccess: () => handleSuccess(),
                //     onError: handleError,
                // });
                break;
            default:
                handleSuccess();
                break;
        }
    }

    const onConfirm = () => {
        onPostModalClose();
        goToNextStep();
    };

    const getStatus = (): EnhancedQueryStatus => {
        switch (true) {
            case isLoading:
                return 'pending';
            case hasLayoutError:
                return 'error';
            case prefilledFormWidgets.length > 0:
                return 'success';
            default:
                return 'empty';
        }
    };

    const onClickErrorButton = () => {
        resetStep();
        setHasLayoutError(false);
    };

    useEffect(() => {
        // clean up useForm instance when the component is unmounted to avoid having multiple forms with the same hook instance
        methods.reset();
        setHasLayoutError(false);
        setValidationErrors([]);
        setIsSubmitting(false);

        const fetchPrefilledFormWidgets = () => {
            prefillForm({
                onStart: () => setIsLoading(true),
                onSuccess: (prefillFormWidgets) =>
                    setPrefilledFormWidgets(prefillFormWidgets),
                onError: () => {
                    setHasLayoutError(true);
                },
                onEnd: () => setIsLoading(false),
            });
        };

        fetchPrefilledFormWidgets();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formType, additionalData]);

    return (
        <LayoutBoundary
            status={getStatus()}
            py="8"
            errorButtons={[
                {
                    text: coreSharedMessages.buttonBackHome,
                    onClick: onClickErrorButton,
                },
            ]}
            messages={LAYOUT_MESSAGES}
            as="div"
            {...(isMobile ? { pb: 20 } : {})}>
            <Stack gap="6" noValidate as="form">
                {validationErrors.length > 0 && (
                    <FormErrors
                        errors={validationErrors}
                        wrapperProps={{ mb: 0 }}
                    />
                )}

                {title && (
                    <Heading as="h2" textStyle="h2">
                        <FormattedMessage {...title} />
                    </Heading>
                )}

                <FormProvider {...methods}>
                    {!!topFormComponent && topFormComponent({ handleError })}
                    {prefilledFormWidgets?.map(({ type, id, ...props }) => (
                        <FormLibrarian
                            key={id}
                            type={type}
                            props={{
                                id,
                                methods: {
                                    setError: setError,
                                    errors: errors,
                                    clearErrors: clearErrors,
                                },
                                ...props,
                            }}
                        />
                    ))}
                    {!!bottomFormComponent &&
                        bottomFormComponent({ handleError })}
                    {!!postSuccessModalComponent &&
                        isPostSuccessModalOpen &&
                        postSuccessModalComponent({
                            isOpen: isPostSuccessModalOpen,
                            onClose: onPostModalClose,
                            onConfirm,
                        })}
                    <HStack justifyContent="flex-end">
                        {cancelButtonMessage && (
                            <Button
                                bg="primary.50"
                                color="primary.main"
                                size={isMobile ? 'lg' : 'md'}
                                onClick={() =>
                                    handleNextStep(null, cancelRedirectStep)
                                }
                                isDisabled={isSubmitting}>
                                <FormattedMessage {...cancelButtonMessage} />
                            </Button>
                        )}
                        {prefilledFormWidgets.length > 0 && (
                            <Button
                                colorScheme="primary"
                                size={isMobile ? 'lg' : 'md'}
                                onClick={methods.handleSubmit(handleSubmitForm)}
                                isLoading={isSubmitting}>
                                <FormattedMessage {...nextButtonMessage} />
                            </Button>
                        )}
                    </HStack>
                    {__DEV__ && <DevTool control={methods.control} />}
                </FormProvider>
            </Stack>
        </LayoutBoundary>
    );
}
