import { StepComponentProps, WizardType } from 'lib/form-wizard/types';
import FormContainer from './FormWizard/FormContainer';
import { PrimoStepsEnum } from '../types';
import BankDetailsStepTopAlert from './BankDetailsStepTopAlert';
// import PartnerBankDetails from './PartnerBankDetails';
import BankDetailsConfirmationStep from './BankDetailsConfirmationStep';
import { useBeneficiaryBankDetails } from '../api';

const BankDetailsStep = (props: StepComponentProps) => {
    const { data: bankDetails } = useBeneficiaryBankDetails();
    const additionalData = {
        ...props.additionalData,
        requiredSignature: bankDetails?.requiredSignature,
    };

    return (
        <FormContainer
            {...props}
            wizardType={WizardType.PRIMO}
            formType={PrimoStepsEnum.BANK_DETAILS}
            topFormComponent={() => <BankDetailsStepTopAlert />}
            // Temporary disabled, see https://optisantis.atlassian.net/browse/DTA-1460
            // bottomFormComponent={(props) => <PartnerBankDetails {...props} />}
            additionalData={additionalData}
            postSuccessModalComponent={(props) => (
                <BankDetailsConfirmationStep {...props} />
            )}
        />
    );
};

export default BankDetailsStep;
