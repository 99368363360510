import { Button, useDisclosure } from '@chakra-ui/react';
import { ChevronRightIcon } from 'design-system/icons';
import { primoSocialSecurityStepMessages } from '../i18n';
import { FormattedMessage, useIntl } from 'react-intl';
import HelpModal from './Layouts/NavBar/HelpModal';
import Alert from 'design-system/components/Alert';

const SocialSecurityStepAlert = () => {
    const {
        isOpen: helpModalIsOpen,
        onOpen: openHelpModal,
        onClose: closeHelpModal,
    } = useDisclosure();
    const { formatMessage } = useIntl();

    return (
        <>
            {helpModalIsOpen && (
                <HelpModal
                    isOpen={helpModalIsOpen}
                    onClose={closeHelpModal}
                    openAccordion="security-social"
                />
            )}
            <Alert
                displayedTitle={formatMessage(
                    primoSocialSecurityStepMessages.socialSecurityOrganizationCodeInfo
                )}
                description={
                    <Button
                        color="blue.500"
                        variant="link"
                        alignSelf="flex-start"
                        whiteSpace="normal"
                        textAlign="left"
                        rightIcon={<ChevronRightIcon />}
                        onClick={openHelpModal}>
                        <FormattedMessage
                            {...primoSocialSecurityStepMessages.socialSecurityGetCertificate}
                        />
                    </Button>
                }
                icon="infoIcon"
                color="info"
            />
        </>
    );
};

export default SocialSecurityStepAlert;
