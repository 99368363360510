import axios from 'axios';
import { APIError, FetchQueryParams } from 'core';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { PersonalData } from '../types';

export const personalDataPath = `/api/proxy/primo/personal-data`;

export async function fetchPersonalData({ queryKey: [urn] }: FetchQueryParams) {
    try {
        const res = await axios.get(urn);

        return res.data.data;
    } catch (error) {
        throw {
            code: error.response?.status,
        };
    }
}

export function usePersonalData(enabled: boolean = true) {
    return useQuery<PersonalData, APIError>({
        queryKey: [personalDataPath],
        queryFn: fetchPersonalData,
        enabled,
    });
}

async function postPersonalData(
    data: Pick<PersonalData, 'address' | 'contact'>
) {
    try {
        await axios.post(personalDataPath, data);
    } catch (error) {
        throw {
            code: error.response?.status,
            errors: error.response?.data?.errors,
        };
    }
}

export function usePostPersonalData() {
    const queryClient = useQueryClient();

    return useMutation<void, APIError, unknown>({
        mutationFn: postPersonalData,
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [personalDataPath],
            });
        },
    });
}
