import React from 'react';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import {
    ChallengeRouterQueries,
    ChallengeError,
    useFormRedirect,
    useRedirect,
    FetchQueryParams,
} from 'core';

const path = '/api/mfa';

export function useMFAPut() {
    const router = useRouter();
    const { redirectAfterLogin } = useFormRedirect();
    const { redirect } = useRedirect();
    const { strategy, id } = router.query as ChallengeRouterQueries;
    const token = router.query?.token as string;
    const [error, setError] = React.useState<ChallengeError>();

    async function putStrategy({
        queryKey: [urn, token, id],
    }: FetchQueryParams) {
        try {
            const response = await axios.put(
                urn,
                id ? { channelIdentifier: id } : { token, strategy }
            );
            return response.data;
        } catch (err) {
            const { data } = err.response;

            if (data.code === 'MFA_REDIRECT_PASSWORD') {
                return redirect({
                    pathname: '/password-upgrade/[token]',
                    query: data.data,
                });
            }

            if (data.code === 'MFA_REDIRECT_USERNAME') {
                return redirect({
                    pathname: '/username-upgrade/[username]/[token]',
                    query: data.data,
                });
            }

            // Pour que l'utilisateur puisse entrer sont code, on doit
            // afficher le formulaire si le code mfa a déjà été envoyé.
            if (data.code === 'MFA_CODE_ALREADY_SENT') {
                return;
            }

            if (data.code === 'MFA_ALREADY_VALIDATED') {
                redirectAfterLogin();
            }

            setError(data);
        }
    }

    const { status } = useQuery({
        queryKey: [path, token, id],
        queryFn: putStrategy,
    });

    return { error, status };
}
