import { WidgetKeys } from 'core';
import { InternationalPhoneNumberProps } from 'core/components/forms/widgets/InternationalPhoneNumber';
import { PersonalData } from 'lib/primo';

export const getPrimoPersonnalDataForm = (
    personalData: PersonalData
): (WidgetKeys | InternationalPhoneNumberProps)[] => [
    {
        validations: {
            required: true,
        },
        help: null,
        defaultValue: personalData?.contact?.email || null,
        description: null,
        title: 'common.email.title',
        placeholder: 'common.email.placeholder',
        type: 'email',
        id: 'contact.email',
    },
    {
        label: 'phoneNumber',
        validations: {
            authorizedCountries: ['FR'],
            unauthorizedCountries: [],
            phoneType: 'mobile',
            required: true,
        },
        defaultCountry: 'FR',
        help: null,
        description: null,
        title: 'phone-number.mobile.title',
        defaultValue: personalData?.contact?.mobilePhoneNumber || null,
        placeholder: 'common.phone.placeholder',
        type: 'internationalPhoneNumber',
        id: 'contact.mobilePhoneNumber',
    },
    {
        label: 'phoneNumber',
        validations: {
            authorizedCountries: ['FR'],
            unauthorizedCountries: [],
            phoneType: null,
            required: false,
        },
        defaultCountry: 'FR',
        help: null,
        description: null,
        title: 'phone-number.secondary.title',
        defaultValue: personalData?.contact?.secondPhoneNumber || null,
        placeholder: 'common.phone.placeholder',
        type: 'internationalPhoneNumber',
        id: 'contact.secondPhoneNumber',
    },
    {
        type: 'text',
        title: 'address.street-number-and-name.title',
        id: 'address.streetNumberAndName',
        validations: {
            required: true,
            lengthMax: 32,
            allowSpecialCharacters: false,
        },
        defaultValue: personalData?.address?.streetNumberAndName,
    },
    {
        type: 'text',
        title: 'address.postal-code.title',
        id: 'address.postalCode',
        validations: {
            required: true,
            lengthMin: 5,
            lengthMax: 5,
            allowLetters: false,
        },
        defaultValue: personalData?.address?.postalCode,
    },
    {
        type: 'text',
        title: 'address.city.title',
        id: 'address.city',
        validations: {
            required: true,
            allowSpecialCharacters: true,
            allowNumbers: false,
        },
        defaultValue: personalData?.address?.city,
    },
    {
        type: 'text',
        title: 'address.additional-address-one.title',
        id: 'address.additionalAddress',
        validations: {
            required: false,
            lengthMax: 32,
            allowSpecialCharacters: false,
        },
        defaultValue: personalData?.address?.additionalAddress,
    },
    {
        type: 'text',
        title: 'address.additional-address-two.title',
        id: 'address.additionalAddress2',
        validations: {
            required: false,
            lengthMax: 32,
            allowSpecialCharacters: false,
        },
        defaultValue: personalData?.address?.additionalAddress2,
    },
];
