import {
    Text,
    Stack,
    Box,
    Heading,
    InputGroup,
    Input,
    InputRightElement,
    Tooltip,
    useClipboard,
    VStack,
    InputProps,
    StackProps,
} from '@chakra-ui/react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { UpdateIcon, CopyIcon, CheckCircleIcon } from 'design-system/icons';
import { useWindowBreakpoints } from 'design-system/hooks';

type NewUsernameProps = {
    username: string;
    inputProps?: Partial<InputProps>;
    contentProps?: Partial<StackProps>;
    color?: string;
};

const intlMessages = defineMessages({
    usernameupgrade: {
        id: 'components.username-upgrade.tooltip.id',
        defaultMessage: 'Nouvel identifiant',
    },
    copy: {
        id: 'components.username-upgrade.tooltip.copy',
        defaultMessage: 'Copier',
    },
    copied: {
        id: 'components.username-upgrade.tooltip.copied',
        defaultMessage: 'Copié',
    },
});

const NewUsername = ({
    username,
    inputProps = {},
    contentProps = {},
    color = 'secondary.main',
}: NewUsernameProps) => {
    const { isMobile } = useWindowBreakpoints();
    const intl = useIntl();

    const value = username;

    const { hasCopied, onCopy } = useClipboard(value, 3000);

    return (
        <Box
            mt="12"
            px={isMobile ? '7' : '0'}
            borderRadius="md"
            backgroundColor="grey.100"
            position="relative"
            flex="auto">
            <Stack
                position="absolute"
                left="50%"
                marginLeft="-50px"
                top="0%"
                marginTop="-50px"
                width="86px"
                height="86px"
                backgroundColor="white"
                rounded="100%">
                <Box
                    backgroundColor="primary.main"
                    rounded="100%"
                    width="47px"
                    height="47px"
                    left="80%"
                    marginLeft="-50px"
                    top="87%"
                    marginTop="-50px"
                    position="absolute"
                    textAlign="center">
                    <UpdateIcon
                        color="white"
                        position="absolute"
                        top="50%"
                        left="50%"
                        transform="translate(-50%,-50%)"
                        fontSize="xl"
                    />
                </Box>
            </Stack>

            <VStack
                h="full"
                px={!isMobile && '6'}
                pt="10"
                textAlign="center"
                spacing="7"
                {...contentProps}>
                <Box>
                    <Heading
                        pt="4"
                        as="h3"
                        fontSize="xl"
                        color="grey.700"
                        fontWeight="semibold">
                        <FormattedMessage
                            id="components.username-upgrade.input.heading"
                            defaultMessage="Voici votre nouvel"
                        />
                        <br />
                        <Heading
                            as="span"
                            display="block"
                            fontSize="xl"
                            color="black"
                            fontWeight="semibold"
                            textTransform="uppercase"
                            mb="2.5">
                            <FormattedMessage
                                id="components.username-upgrade.input.subheading"
                                defaultMessage="Identifiant"
                            />
                        </Heading>
                    </Heading>

                    <Text fontSize="sm" lineHeight="1.3">
                        <FormattedMessage
                            id="components.username-upgrade.input.description"
                            defaultMessage="Cliquez sur {icon}
                                pour le copier dans votre presse-papier et le
                                noter en lieu sûr."
                            values={{
                                icon: <CopyIcon mx="0.5" color={color} />,
                            }}
                        />
                    </Text>
                </Box>

                <InputGroup my="4">
                    <Tooltip
                        hasArrow
                        label={intl.formatMessage(intlMessages.usernameupgrade)}
                        bg="white"
                        color="black"
                        placement="top"
                        fontSize="sm">
                        <Input
                            readOnly
                            py="7"
                            textAlign="center"
                            fontSize="2xl"
                            letterSpacing="0.5em"
                            fontWeight="semibold"
                            fontFamily="mono"
                            color="primary.main"
                            backgroundColor="white"
                            borderRadius="10px"
                            border="1px solid"
                            paddingRight="10"
                            value={username}
                            {...inputProps}
                        />
                    </Tooltip>

                    <Tooltip
                        hasArrow
                        label={
                            hasCopied
                                ? intl.formatMessage(intlMessages.copied)
                                : intl.formatMessage(intlMessages.copy)
                        }
                        bg="white"
                        color="black"
                        placement="top"
                        fontSize="sm"
                        isOpen={hasCopied ? true : undefined}>
                        <InputRightElement
                            as="button"
                            type="button"
                            aria-label={intl.formatMessage(intlMessages.copy)}
                            onClick={onCopy}
                            width="38px"
                            height="38px"
                            backgroundColor="grey.100"
                            borderRadius="sm"
                            margin="auto"
                            position="absolute"
                            top="0"
                            bottom="0"
                            right="2.5"
                            cursor="pointer">
                            {!hasCopied ? (
                                <CopyIcon
                                    position="absolute"
                                    fontSize="xl"
                                    color={color}
                                />
                            ) : (
                                <CheckCircleIcon
                                    position="absolute"
                                    fontSize="xl"
                                    color={color}
                                    boxSize="6"
                                />
                            )}
                        </InputRightElement>
                    </Tooltip>
                </InputGroup>
            </VStack>
        </Box>
    );
};
export default NewUsername;
