import { Button, Container, Stack } from '@chakra-ui/react';
import { Heading, coreErrors, coreSharedMessages } from 'core';
import { StepComponentProps } from 'lib/form-wizard/types';
import { FormattedMessage, MessageDescriptor, useIntl } from 'react-intl';
import {
    primoAddBeneficiariesStepMessages,
    primoReinforcingGuaranteesMessages,
} from '../i18n';
import {
    additionalProductsPath,
    useAdditionalProducts,
    usePostAdditionalProducts,
} from '../api';
import Alert from 'design-system/components/Alert';
import { useWindowBreakpoints } from 'design-system/hooks';
import LayoutBoundary from 'components/Layouts/LayoutBoundary';
import { sharedMessages } from 'lib/shared';
import ReinforcingGuaranteesCard from './ReinforcingGuaranteesCard';
import { useEffect, useState } from 'react';
import GuaranteesCalculationDropdown, {
    GuaranteesCalculationDropdownProps,
} from './GuaranteesCalculationDropdown';
import { OptionBase } from '../types';
import { useQueryClient } from '@tanstack/react-query';

const ReinforcingGuarantees = ({
    handleNextStep,
    resetStep,
}: StepComponentProps) => {
    const { formatMessage } = useIntl();
    const { isMobile } = useWindowBreakpoints();
    const {
        data: additionalProductsData,
        status: additionalProductsStatus,
        error: additionalProductsError,
    } = useAdditionalProducts();
    const { mutate, isPending } = usePostAdditionalProducts();
    const [hasLayoutError, setHasLayoutError] = useState<boolean>(false);
    const [selectedOption, setSelectedOption] = useState<OptionBase | null>(
        null
    );
    const [selectedSupplementary, setSelectedSupplementary] =
        useState<OptionBase | null>(null);
    const queryClient = useQueryClient();
    const displayAdditionalContribution =
        additionalProductsData?.base !== null ||
        selectedOption !== null ||
        selectedSupplementary !== null;

    useEffect(() => {
        if (additionalProductsData) {
            setSelectedOption(
                additionalProductsData?.options?.find(
                    (option) => option.subscribed
                ) || null
            );
            setSelectedSupplementary(
                additionalProductsData?.supplementaries?.find(
                    (supplementary) => supplementary.subscribed
                ) || null
            );
        }
    }, [additionalProductsData]);

    const _renderSection = (
        titleMessage: MessageDescriptor,
        descriptionMessage: MessageDescriptor,
        data: OptionBase[],
        selectedCard: OptionBase,
        setSelectedCard: (card: OptionBase) => void,
        tagColor = 'guarantees.option'
    ) =>
        data?.length > 0 && (
            <Stack spacing="4">
                <Heading fontSize="2xl" as="h2" fontWeight="semibold">
                    <FormattedMessage {...titleMessage} />
                </Heading>
                <FormattedMessage {...descriptionMessage} />
                <ReinforcingGuaranteesCard
                    cardContent={data}
                    selectedCard={selectedCard}
                    setSelectedCard={setSelectedCard}
                    tagColor={tagColor}
                />
            </Stack>
        );

    const guaranteesCalculationRows: GuaranteesCalculationDropdownProps['guaranteesCalculationRows'] =
        [
            {
                label: (
                    <FormattedMessage
                        {...primoAddBeneficiariesStepMessages.extendedBasicCover}
                    />
                ),
                amount: additionalProductsData?.base?.amount || 0,
                debitMethod: additionalProductsData?.base?.debitMethod || '',
            },
            {
                label: selectedOption?.label || '',
                amount: selectedOption?.amount || 0,
                debitMethod: selectedOption?.debitMethod || '',
            },
            {
                label: selectedSupplementary?.label || '',
                amount: selectedSupplementary?.amount || 0,
                debitMethod: selectedSupplementary?.debitMethod || '',
            },
        ].filter((block) => block.amount > 0);

    const handleNextStepPost = () => {
        mutate(
            {
                option: selectedOption?.identifier || null,
                supplementary: selectedSupplementary?.identifier || null,
            },
            {
                onSuccess: () => {
                    queryClient.invalidateQueries({
                        queryKey: [additionalProductsPath],
                        exact: true,
                    });
                    handleNextStep();
                },
                onError: () => {
                    setHasLayoutError(true);
                },
            }
        );
    };

    const onClickErrorButton = () => {
        resetStep();
        setHasLayoutError(false);
    };

    return (
        <Container py="10" mb="10">
            <LayoutBoundary
                p="0"
                mt="4"
                mb="2"
                status={hasLayoutError ? 'error' : additionalProductsStatus}
                errorCode={additionalProductsError?.code}
                messages={{
                    error: coreErrors.serviceUnavailableDescription,
                    errorDescription: coreErrors.serviceUnavailable,
                }}
                errorButtons={[
                    {
                        text: coreSharedMessages.buttonBackHome,
                        onClick: onClickErrorButton,
                    },
                ]}>
                <Stack spacing="16">
                    <Stack spacing="8">
                        <FormattedMessage
                            {...primoReinforcingGuaranteesMessages.reinforcingGuaranteesDescription}
                        />
                        <Alert
                            displayedTitle={formatMessage(
                                primoReinforcingGuaranteesMessages.reinforcingGuaranteesInfo
                            )}
                            icon="InfoIcon"
                            color="info"
                            my="0"
                        />
                        {_renderSection(
                            primoReinforcingGuaranteesMessages.optionsTitle,
                            primoReinforcingGuaranteesMessages.optionsDescription,
                            additionalProductsData?.options,
                            selectedOption,
                            setSelectedOption
                        )}
                        {_renderSection(
                            primoReinforcingGuaranteesMessages.supplementariesTitle,
                            primoReinforcingGuaranteesMessages.supplementariesDescription,
                            additionalProductsData?.supplementaries,
                            selectedSupplementary,
                            setSelectedSupplementary,
                            'guarantees.supplementary'
                        )}
                    </Stack>
                    {additionalProductsData &&
                        displayAdditionalContribution && (
                            <Stack spacing="6">
                                <Heading
                                    fontSize="2xl"
                                    as="h3"
                                    mb="1"
                                    fontWeight="semibold">
                                    <FormattedMessage
                                        {...primoAddBeneficiariesStepMessages.additionalContributionTitle}
                                    />
                                </Heading>
                                <Alert
                                    displayedTitle={formatMessage(
                                        primoReinforcingGuaranteesMessages.additionalProductsDescription
                                    )}
                                    icon="InfoIcon"
                                    color="info"
                                    m="0"
                                />
                                <GuaranteesCalculationDropdown
                                    guaranteesCalculationRows={
                                        guaranteesCalculationRows
                                    }
                                />
                            </Stack>
                        )}
                </Stack>
                <Stack mt="10">
                    <Button
                        alignSelf="end"
                        colorScheme="primary"
                        size={isMobile ? 'lg' : 'md'}
                        w="fit-content"
                        onClick={handleNextStepPost}
                        isLoading={isPending}>
                        <FormattedMessage {...sharedMessages.next} />
                    </Button>
                </Stack>
            </LayoutBoundary>
        </Container>
    );
};

export default ReinforcingGuarantees;
