import { FormattedMessage } from 'react-intl';
import { Text } from '@chakra-ui/react';
import { primoSepaMandateStepMessages } from '../i18n';

const CodeVericationStepDescription = () => (
    <Text
        textAlign="left"
        fontSize="md"
        lineHeight="5"
        color="black"
        mb="3"
        mt="8">
        <FormattedMessage
            {...primoSepaMandateStepMessages.sepaMandateCodeVerificationDescription}
        />
    </Text>
);

export default CodeVericationStepDescription;
