import { useContext, useState } from 'react';
import { Container, Button, Stack, Box } from '@chakra-ui/react';
import { StepComponentProps } from 'lib/form-wizard/types';
import { useIntl, FormattedMessage } from 'react-intl';
import { primoSepaMandateStepMessages } from '../i18n';
import LayoutBoundary from 'components/Layouts/LayoutBoundary';
import Alert from 'design-system/components/Alert';
import {
    LanguageContext,
    addDocumentViewerScrollListener,
    coreErrors,
    coreSharedMessages,
} from 'core';
import { useMergedDocuments, signatureMessages } from 'core/lib/signature';
import { useSignatureSepaFile } from '../api';
import { SignaturePDFViewer } from 'core/components/signature/SignaturePDFViewer';
import { sharedMessages } from 'lib/shared';
import { useWindowBreakpoints } from 'design-system/hooks';

const VerifySepaMandateStep = ({
    handleNextStep,
    resetStep,
}: StepComponentProps) => {
    const { formatMessage } = useIntl();
    const { locale } = useContext(LanguageContext);
    const { data: files, status } = useSignatureSepaFile();
    const { isMobile } = useWindowBreakpoints();
    const [hasDocumentBeenScrolledToEnd, setHasDocumentBeenScrolledToEnd] =
        useState<boolean>(false);
    const [isErrorShown, setIsErrorShown] = useState<boolean>(false);

    const { fetchStatus: mergeStatus, mergePdfData } = useMergedDocuments(
        files,
        'sepa',
        'primo',
        'signature-sepa-primo'
    );

    const layoutBoundaryStatus = status === 'error' ? status : mergeStatus;

    const hasScrolledDocumentCallback = () => {
        setHasDocumentBeenScrolledToEnd(true);
    };

    const hasReachedBottomCallback = () => {
        setHasDocumentBeenScrolledToEnd(true);
    };

    const onClickNext = () => {
        if (hasDocumentBeenScrolledToEnd) handleNextStep();
        else setIsErrorShown(true);
    };

    return (
        <Container pt="12" mb="10">
            <LayoutBoundary
                status={layoutBoundaryStatus}
                px={0}
                py={0}
                errorButtons={[
                    {
                        text: coreSharedMessages.buttonBackHome,
                        onClick: resetStep,
                    },
                ]}
                messages={{
                    error: coreErrors.serviceUnavailableDescription,
                    errorDescription: coreErrors.serviceUnavailable,
                }}>
                <Alert
                    displayedTitle={formatMessage(
                        primoSepaMandateStepMessages.sepaMandateDocumentDescription
                    )}
                    icon="infoFlashIcon"
                    color="primary"
                />
                <Box
                    {...(layoutBoundaryStatus === 'success' && {
                        bg: 'bg.medium',
                        px: isMobile ? 6 : 8,
                        py: isMobile ? 5 : '50px',
                    })}>
                    <SignaturePDFViewer
                        onDocumentLoad={() =>
                            addDocumentViewerScrollListener({
                                hasScrolledDocumentCallback,
                                hasReachedBottomCallback,
                            })
                        }
                        mergePdfData={mergePdfData}
                        locale={locale}
                    />
                </Box>
                {isErrorShown && !hasDocumentBeenScrolledToEnd && (
                    <Alert
                        displayedTitle={formatMessage(
                            signatureMessages.documentPreviewAlert
                        )}
                        icon="AlertIcon"
                        color="error"
                    />
                )}
                <Stack mt="76px">
                    <Button
                        alignSelf="end"
                        colorScheme="primary"
                        size={isMobile ? 'lg' : 'md'}
                        onClick={onClickNext}>
                        <FormattedMessage {...sharedMessages.validate} />
                    </Button>
                </Stack>
            </LayoutBoundary>
        </Container>
    );
};

export default VerifySepaMandateStep;
