import {
    Box,
    Container,
    Flex,
    HStack,
    Stack,
    Text,
    useDisclosure,
} from '@chakra-ui/react';
import {
    Heading,
    SafeFormattedMessage,
    capitalizeHyphenatedName,
    formatPhoneNumber,
    formatSocialSecurityNumber,
} from 'core';
import { StepComponentProps } from 'lib/form-wizard/types';
import { FormattedMessage, MessageDescriptor, useIntl } from 'react-intl';
import { primoIntroductionMessages, primoWelcomeMessages } from '../i18n';
import { siteConfig } from 'lib/site';
import {
    DesktopIntroIllustration,
    MobileIntroIllustration,
} from 'components/onboarding/v2/illustrations';
import { CheckIcon, ChevronRightIcon, CrossIcon } from 'design-system/icons';
import { useContactCenter, usePersonalData } from '../api';
import { useState } from 'react';
import { useWindowBreakpoints } from 'design-system/hooks';
import { CardInfos } from 'design-system/components';
import LayoutBoundary from 'components/Layouts/LayoutBoundary';
import Alert from 'design-system/components/Alert';
import HelpModal from './Layouts/NavBar/HelpModal';
import { sharedMessages } from 'lib/shared';

const Welcome = ({ title, handleNextStep, wizardType }: StepComponentProps) => {
    const { appName } = siteConfig;
    const { formatDate } = useIntl();
    const { isMobile } = useWindowBreakpoints();
    const { data: personalData, status } = usePersonalData();
    const { data: contactCenter } = useContactCenter();
    const [showAlertWarning, setShowAlertWarning] = useState<boolean>(false);
    const { formatMessage } = useIntl();
    const showAlert = status === 'error' || showAlertWarning;
    const {
        isOpen: helpModalIsOpen,
        onOpen: openHelpModal,
        onClose: closeHelpModal,
    } = useDisclosure();

    const _renderHelpCard = () => (
        <Stack
            bg="blue.50"
            borderRadius="lg"
            p="6"
            mb="8"
            spacing="4"
            display={'flex'}
            w="full">
            <Stack h="full" justifyContent="space-between">
                <Text fontWeight="bold">
                    <FormattedMessage
                        {...primoIntroductionMessages.introductionDoc}
                    />
                </Text>
                <Text pl="2">
                    <FormattedMessage
                        {...primoIntroductionMessages.introductionDocList}
                    />
                </Text>
            </Stack>

            <Text
                color="treatmentStatus.received"
                fontWeight="semibold"
                cursor="pointer"
                onClick={openHelpModal}
                data-group>
                <FormattedMessage
                    {...sharedMessages.getSocialSecurityCertificate}
                />
                <ChevronRightIcon
                    h="4"
                    w="4"
                    ml="1"
                    mb="1"
                    _groupHover={{
                        transform: 'translateX(4px) scale(1.2)',
                        transition: 'transform 0.2s ease-in-out',
                    }}
                />
            </Text>
        </Stack>
    );

    const _renderCTA = (
        title: MessageDescriptor,
        iconColor: string,
        icon: JSX.Element,
        onClick?: () => void
    ) => {
        return (
            <Stack
                w="full"
                cursor="pointer"
                flexDir="column"
                p="4"
                rounded="lg"
                border="1px solid"
                borderColor="strokes.medium"
                onClick={onClick}>
                <HStack w="full" justifyContent="space-between">
                    <HStack spacing="4">
                        <Flex bg={iconColor} p="3" rounded="full">
                            {icon}
                        </Flex>
                        <Stack spacing="0">
                            <Heading
                                as="h3"
                                textStyle="h5"
                                fontWeight="semibold">
                                <FormattedMessage {...title} />
                            </Heading>
                        </Stack>
                    </HStack>
                </HStack>
            </Stack>
        );
    };

    return (
        <>
            {helpModalIsOpen && (
                <HelpModal
                    isOpen={helpModalIsOpen}
                    onClose={closeHelpModal}
                    openAccordion="security-social"
                />
            )}
            <Container py="10" mb="10">
                <Stack spacing="8">
                    <Stack spacing="4">
                        <Stack
                            bg="bg.extralight"
                            borderRadius="lg"
                            spacing={isMobile ? '2' : '16'}
                            p="6"
                            display={'flex'}
                            w="full"
                            alignItems="center"
                            flexDir={isMobile ? 'column-reverse' : 'row'}>
                            <Stack
                                spacing="3"
                                py="4"
                                h="full"
                                justifyContent="space-between">
                                <Heading
                                    fontSize="2xl"
                                    color="texts.main"
                                    fontWeight="semibold">
                                    <SafeFormattedMessage
                                        {...title}
                                        values={{
                                            appName: appName,
                                        }}
                                        debugKey={
                                            primoWelcomeMessages.welcomeTitle.id
                                        }
                                    />
                                </Heading>
                                <Text>
                                    <FormattedMessage
                                        {...primoWelcomeMessages.welcomeDescription}
                                    />
                                </Text>
                            </Stack>
                            {isMobile ? (
                                <MobileIntroIllustration w="150px" h="150px" />
                            ) : (
                                <DesktopIntroIllustration w="256px" h="168px" />
                            )}
                        </Stack>
                        {_renderHelpCard()}
                    </Stack>
                    <Heading fontSize="lg" as="h2" fontWeight="semibold">
                        <FormattedMessage
                            {...primoWelcomeMessages.welcomeCheckDataTitle}
                        />
                    </Heading>
                    {status !== 'error' && (
                        <LayoutBoundary p="0" status={status}>
                            <CardInfos
                                name={capitalizeHyphenatedName(
                                    `${personalData?.firstName} ${personalData?.lastName}`
                                )}
                                items={[
                                    {
                                        title: (
                                            <FormattedMessage
                                                {...primoWelcomeMessages.welcomeProfileBirthDate}
                                            />
                                        ),
                                        value: formatDate(
                                            personalData?.birthDate
                                        ),
                                    },
                                    {
                                        title: (
                                            <FormattedMessage
                                                {...primoWelcomeMessages.welcomeProfileSocialSecurityNumber}
                                            />
                                        ),
                                        value: formatSocialSecurityNumber.loose(
                                            personalData?.socialSecurityNumber
                                        ),
                                        isFullWidth: true,
                                    },
                                ]}
                            />
                        </LayoutBoundary>
                    )}

                    {status !== 'error' && (
                        <Stack
                            w="full"
                            flexDir={isMobile ? 'column-reverse' : 'row'}>
                            {_renderCTA(
                                primoWelcomeMessages.welcomeCheckDataIncorrectData,
                                'error.light',
                                <CrossIcon w="6" h="6" color="error.dark" />,
                                () => setShowAlertWarning(true)
                            )}
                            {_renderCTA(
                                primoWelcomeMessages.welcomeCheckDataCorrectData,
                                'success.light',
                                <CheckIcon w="6" h="6" color="success.dark" />,
                                () => handleNextStep({ wizardType })
                            )}
                        </Stack>
                    )}
                    {showAlert && (
                        <Alert
                            displayedTitle={
                                status === 'error'
                                    ? formatMessage(
                                          primoWelcomeMessages?.welcomeInfoMissingDataTitle
                                      )
                                    : formatMessage(
                                          primoWelcomeMessages?.welcomeInfoIncorrectInfoTitle
                                      )
                            }
                            description={
                                (status === 'error' || contactCenter) && (
                                    <Box mt="1">
                                        {status === 'error' && (
                                            <FormattedMessage
                                                {...primoWelcomeMessages?.welcomeInfoMissingDataDescription}
                                            />
                                        )}
                                        {contactCenter?.phoneNumber && (
                                            <SafeFormattedMessage
                                                {...primoWelcomeMessages?.welcomeInfoIncorrectInfoDescription}
                                                values={{
                                                    phoneNumber:
                                                        formatPhoneNumber.loose(
                                                            contactCenter?.phoneNumber
                                                        ),
                                                }}
                                                debugKey={`Primo - Welcome contactCenter phoneNumber - ${contactCenter?.phoneNumber}`}
                                            />
                                        )}
                                    </Box>
                                )
                            }
                            icon={status === 'error' && 'InfoIcon'}
                            color={status === 'error' ? 'error' : 'warning'}
                        />
                    )}
                </Stack>
            </Container>
        </>
    );
};

export default Welcome;
