import React from 'react';
import { AppProps } from 'next/app';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react';
import LanguageSwitch from 'components/LanguageSwitch';

import { ThemeProvider } from 'themes';
import { siteConfig } from 'lib/site';
import { CACHE_TIME } from 'lib/shared';
import { FontFaces } from 'components/FontFaces';
import { UserProvider } from 'lib/user';
import {
    LoadingProgressBar,
    CombineProviders,
    combineFCProps,
    Matomo,
    UtilsProvider,
    LinkIntl,
    LanguageProvider,
} from 'core';
import ErrorInterceptor from 'components/ErrorInterceptor';
import routes from 'routes';
import { useHandleSubmitResponse } from 'lib/forms';
import { LayoutBoundary } from 'components/Layouts';
import ConsentFormModal from 'components/ConsentFormModal';
import { DesignSystemProvider } from 'design-system/lib';
import en_GB from '../locales/en-GB.json';
import fr_FR from '../locales/fr-FR.json';
import { NOT_AUTH_ROUTES } from 'lib/user/contexts/consts';
import * as Sentry from '@sentry/nextjs';
import getConfig from 'next/config';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: false,
            gcTime: CACHE_TIME,
            staleTime: CACHE_TIME,
        },
    },
});

const matomoInstance = createInstance({
    urlBase: process.env.NEXT_PUBLIC_FRONT_URL,
    siteId: parseInt(process.env.NEXT_PUBLIC_MATOMO_ID),
    trackerUrl: 'https://matomo.optis.xyz/matomo.php',
    srcUrl: 'https://matomo.optis.xyz/matomo.js',
    configurations: {
        disableCookies: true,
    },
});

const EXCLUDED_ROUTES_LANGUAGE_SWITCHER = [
    '/privacy-policy',
    '/saml/sso',
    '/SAMLPost',
    '/public/preview',
    '/magic-link-mercer',
];

const EXCLUDED_ROUTES_CONSENT_FORM_MODAL = [
    '/onboarding',
    '/account/profile/privacy-policy',
    '/account/profile/terms-of-use',
    '/primo',
];

export default function App({ Component, pageProps, router }: AppProps) {
    const { publicRuntimeConfig } = getConfig();
    Sentry.setTag(
        'build.datetime',
        new Date(publicRuntimeConfig.buildDate).toLocaleString()
    );

    return (
        <CombineProviders
            providers={[
                combineFCProps(MatomoProvider, { value: matomoInstance }),
                combineFCProps(QueryClientProvider, {
                    client: queryClient,
                }),
                combineFCProps(UtilsProvider, {
                    routes,
                    siteConfig,
                    hooks: { useHandleSubmitResponse },
                    layouts: {
                        LayoutBoundary,
                    },
                }),
                combineFCProps(DesignSystemProvider, {
                    LinkComponent: LinkIntl,
                    themeConfig: siteConfig.theme,
                }),
                combineFCProps(LanguageProvider, {
                    locales: { 'en-GB': en_GB, 'fr-FR': fr_FR },
                    notAuthRoutes: NOT_AUTH_ROUTES,
                }),
                combineFCProps(ThemeProvider, {
                    client: siteConfig.theme.partner,
                    variant: siteConfig.theme.variant,
                }),
                combineFCProps(UserProvider),
            ]}>
            <FontFaces />
            <LoadingProgressBar
                height="5"
                bgColor={
                    router.pathname.includes('login')
                        ? 'white'
                        : 'tertiary.main'
                }
            />
            <ErrorInterceptor />
            <Matomo>
                {!EXCLUDED_ROUTES_LANGUAGE_SWITCHER.includes(
                    router.pathname
                ) && <LanguageSwitch />}
                <Component {...pageProps} />
                {!EXCLUDED_ROUTES_CONSENT_FORM_MODAL.includes(
                    router.pathname
                ) && <ConsentFormModal />}
            </Matomo>
            <ReactQueryDevtools
                initialIsOpen={process.env.NODE_ENV === 'development'}
            />
        </CombineProviders>
    );
}
