import { ReactNode } from 'react';
import {
    As,
    Box,
    Divider,
    Flex,
    Heading,
    HStack,
    Stack,
    Text,
    TextProps,
} from '@chakra-ui/react';
import * as icons from 'design-system/icons';
import { uniqueId } from 'lodash';

type ListItemProps = {
    icon?: string;
    label?: string | ReactNode;
    labelProps?: TextProps;
    description?: string | ReactNode;
    descriptionProps?: TextProps;
    isInactive?: boolean;
    inactiveLabel?: string | ReactNode;
    onClick?: () => void;
    hasAction?: boolean;
    wrapperProps?: object;
};

type ListProps = {
    title?: ReactNode | string;
    hasDividers?: boolean;
    hideFirstDivider?: boolean;
    items: ListItemProps[];
    titleAs?: As;
};

export default function List({
    title,
    hasDividers = false,
    hideFirstDivider = false,
    items,
    titleAs = 'h2',
}: ListProps) {
    if (!items || items?.length === 0) {
        return null;
    }

    return (
        <Stack data-testid="list">
            {title && (
                <Heading as={titleAs} fontSize="xl" fontWeight="semibold">
                    {title}
                </Heading>
            )}

            <Stack gap="0" role="list">
                {items?.map((item, idx) => (
                    <Box key={uniqueId()}>
                        {hasDividers && (!hideFirstDivider || idx > 0) && (
                            <Divider data-testid="hr" />
                        )}
                        <ListItem {...item} />
                    </Box>
                ))}
            </Stack>
        </Stack>
    );
}

const focusStyle = {
    bg: 'gray.200',
    borderRadius: 0,
    '.arrow-icon': {
        transform: 'translateX(-10px)',
        transition: 'ease-in-out all 0.3s',
    },
    px: 2,
};

function ListItem({
    icon,
    label,
    labelProps,
    description,
    descriptionProps,
    isInactive,
    inactiveLabel,
    onClick,
    hasAction = false,
    wrapperProps = {},
}: ListItemProps) {
    const Icon = icons[icon];

    return (
        <HStack
            role="listitem"
            alignItems={label && description ? 'flex-start' : 'center'}
            justifyContent="space-between"
            borderRadius="md"
            py="3"
            transition="ease-in-out all 0.3s"
            outline="none"
            _hover={
                hasAction && {
                    ...focusStyle,
                    bg: 'gray.100',
                }
            }
            _focusVisible={focusStyle}
            {...(hasAction && {
                cursor: isInactive ? 'not-allowed' : 'pointer',
            })}
            {...(!isInactive && { onClick })}
            {...wrapperProps}>
            <HStack gap="3">
                {Icon && (
                    <Flex
                        bg="bg.light"
                        rounded="xl"
                        p="1"
                        data-testid="listitem-icon">
                        <Icon
                            h="6"
                            w="6"
                            color={isInactive ? 'texts.light' : 'primary.main'}
                        />
                    </Flex>
                )}
                {(label || description) && (
                    <Stack gap="0" spacing="0">
                        {label && (
                            <Text
                                fontWeight="bold"
                                color={isInactive && 'texts.light'}
                                {...labelProps}>
                                {label}
                            </Text>
                        )}
                        {description && (
                            <Text
                                fontSize="sm"
                                color={isInactive && 'texts.light'}
                                whiteSpace="pre-line"
                                {...descriptionProps}>
                                {description}
                            </Text>
                        )}
                        {isInactive &&
                            (typeof inactiveLabel === 'string' ? (
                                <Text
                                    color="texts.light"
                                    fontWeight="normal"
                                    fontSize="sm">
                                    {inactiveLabel}
                                </Text>
                            ) : (
                                inactiveLabel
                            ))}
                    </Stack>
                )}
            </HStack>
            {hasAction && (
                <icons.ArrowRightIcon
                    className="arrow-icon"
                    fontSize="lg"
                    color={isInactive && 'texts.light'}
                    transition="ease-in-out all 0.3s"
                />
            )}
        </HStack>
    );
}
