import { fromPairs } from 'lodash';
import { Beneficiary as BeneficiaryType } from 'lib/contracts';

type Gender = 'female' | 'male';

type ConnectedGenders = {
    socialSecurity: {
        main?: Gender;
        partner?: Gender;
    };
    refundRib: Gender;
};

function prepareConnectedGenders(beneficiaries: BeneficiaryType[]) {
    const beneficiariesArray = Object.values(beneficiaries);

    return (beneficiary: BeneficiaryType) => {
        // SocialSecurity
        const connectedSocialStatus =
            beneficiary.compulsoryHealthInsurance.socialSecurityConnection;
        const socialGenders = beneficiariesArray.filter((beneficiaryToFind) => {
            if (connectedSocialStatus === 'main_partner') {
                return (
                    beneficiaryToFind.status === 'main' ||
                    beneficiaryToFind.status === 'partner'
                );
            }

            return beneficiaryToFind.status === connectedSocialStatus;
        });

        // RefundRib
        const connectedRefundStatus =
            beneficiary.refundRib?.refundRibConnection;
        const refundGender = beneficiariesArray.find(
            (beneficiaryToFind) =>
                beneficiaryToFind.status === connectedRefundStatus
        )?.gender;

        return {
            socialSecurity: fromPairs(
                socialGenders.map((sg) => [sg.status, sg.gender])
            ),
            refundRib: refundGender,
        } as ConnectedGenders;
    };
}

export type { ConnectedGenders };
export { prepareConnectedGenders };
