import { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Container,
    Flex,
    HStack,
    Stack,
    Text,
    useDisclosure,
} from '@chakra-ui/react';
import {
    Heading,
    capitalizeHyphenatedName,
    coreErrors,
    coreSharedMessages,
} from 'core';
import { StepComponentProps } from 'lib/form-wizard/types';
import { FormattedMessage, useIntl } from 'react-intl';
import { primoAddBeneficiariesStepMessages } from '../i18n';
import { useAdditionalProducts, useBeneficiaries } from '../api';
import { useBeneficiaryItems } from '../hooks';
import { Beneficiary } from '../types';
import Alert from 'design-system/components/Alert';
import { useWindowBreakpoints } from 'design-system/hooks';
import { CardInfos, AddInput } from 'design-system/components';
import LayoutBoundary, {
    LayoutBoundaryProps,
} from 'components/Layouts/LayoutBoundary';
import { relatedActionsMessages } from 'lib/user/i18n/relatedActions';
import {
    beneficiariesStatusMessages,
    paymentFrequencyMessages,
} from 'lib/contracts';
import { sharedMessages } from 'lib/shared';
import AddBeneficiariesModal from './AddBeneficiariesModal';
import DeleteBeneficiaryModal from './DeleteBeneficiaryModal';

const AddBeneficiaries = ({
    handleNextStep,
    resetStep,
}: StepComponentProps) => {
    const { formatMessage } = useIntl();
    const { isMobile } = useWindowBreakpoints();
    const { data: beneficiariesData, status, error } = useBeneficiaries();
    const beneficiariesItems = useBeneficiaryItems(beneficiariesData);
    const [beneficiary, setBeneficiary] = useState<Beneficiary>(null);
    const {
        data: additionalProductsData,
        status: additionalProductsStatus,
        error: additionalProductsError,
    } = useAdditionalProducts();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const {
        isOpen: isDeleteModalOpen,
        onOpen: onOpenDeleteModal,
        onClose: onCloseDeleteModal,
    } = useDisclosure();
    const [layoutStatus, setLayoutStatus] =
        useState<LayoutBoundaryProps['status']>('pending');

    const hasMoreThan24Children = (beneficiaries) => {
        const childCount = beneficiaries.filter(
            (b) => b.beneficiaryType === 'child'
        ).length;
        return childCount > 24;
    };

    const hasAlreadyPartner = (beneficiaries) => {
        return beneficiaries.some((b) => b.beneficiaryType === 'partner');
    };

    const canAddBeneficiary =
        beneficiariesData &&
        hasAlreadyPartner(beneficiariesData) &&
        hasMoreThan24Children(beneficiariesData)
            ? false
            : true;

    const _renderBeneficiaries = () =>
        beneficiariesData.map((beneficiary, index) => (
            <CardInfos
                key={beneficiary.attachedSocialSecurityNumber}
                name={capitalizeHyphenatedName(
                    `${beneficiary.firstName} ${beneficiary.lastName}`
                )}
                tag={{
                    status: formatMessage(
                        beneficiary.beneficiaryType === 'partner'
                            ? primoAddBeneficiariesStepMessages.beneficiaryStatusPartner
                            : beneficiariesStatusMessages.child,
                        {
                            gender: beneficiary.gender,
                        }
                    ),
                    color: `user.${beneficiary.beneficiaryType}`,
                }}
                items={beneficiariesItems[index]}
                onEdit={() => onEditBeneficiary(beneficiary)}
                onDelete={() => onDeleteBeneficiary(beneficiary)}
            />
        ));

    const _renderExtensionGuaranteeCalculation = () => (
        <Box
            onClick={() => {}}
            rounded="md"
            borderColor="strokes.medium"
            borderWidth="1px"
            p="6">
            <Flex
                flexDir={isMobile ? 'column' : 'row'}
                justify={'space-between'}
                gap="6">
                <Stack>
                    <Heading fontSize="sm" as="h3" fontWeight="semibold">
                        <FormattedMessage
                            {...primoAddBeneficiariesStepMessages.extendedBasicCover}
                        />
                    </Heading>
                    <Text fontSize="xs" color="grey.700">
                        <FormattedMessage
                            {...(additionalProductsData?.base?.debitMethod ===
                            'onSalary'
                                ? primoAddBeneficiariesStepMessages.onSalary
                                : primoAddBeneficiariesStepMessages.contributionRIB)}
                        />
                    </Text>
                </Stack>
                {additionalProductsData?.base?.debitMethod !== 'onSalary' && (
                    <HStack color="primary.main">
                        <Heading fontSize="md" as="h3" fontWeight="semibold">
                            {`${
                                additionalProductsData?.base?.amount.toFixed(
                                    2
                                ) || ''
                            } €`}
                        </Heading>
                        <Heading fontSize="sm" as="h3" fontWeight="semibold">
                            {'/ '}
                            <FormattedMessage
                                {...paymentFrequencyMessages.monthly}
                            />
                        </Heading>
                    </HStack>
                )}
            </Flex>
        </Box>
    );

    const onEditBeneficiary = (beneficiary) => {
        onOpen();
        setBeneficiary(beneficiary);
    };

    const onDeleteBeneficiary = (beneficiary) => {
        onOpenDeleteModal();
        setBeneficiary(beneficiary);
    };

    const onClickErrorButton = () => {
        resetStep();
        setLayoutStatus(null);
    };

    useEffect(() => {
        switch (true) {
            case status === 'error' || additionalProductsStatus === 'error':
                setLayoutStatus('error');
                break;
            case status === 'pending' || additionalProductsStatus === 'pending':
                setLayoutStatus('pending');
                break;
            default:
                setLayoutStatus('success');
        }
    }, [status, additionalProductsStatus]);

    return (
        <>
            <DeleteBeneficiaryModal
                isOpen={isDeleteModalOpen}
                onClose={onCloseDeleteModal}
                beneficiary={beneficiary}
                setBeneficiary={setBeneficiary}
            />
            {isOpen && beneficiariesData && (
                <AddBeneficiariesModal
                    isOpen={isOpen}
                    onClose={onClose}
                    beneficiaryEdit={beneficiary}
                    setBeneficiaryEdit={setBeneficiary}
                    hasMoreThan24Children={hasMoreThan24Children(
                        beneficiariesData
                    )}
                    hasAlreadyPartner={hasAlreadyPartner(beneficiariesData)}
                />
            )}
            <Container py="10" mb="10">
                <LayoutBoundary
                    p="0"
                    mt="8"
                    mb="2"
                    status={layoutStatus}
                    errorCode={error?.code || additionalProductsError?.code}
                    messages={{
                        error: coreErrors.serviceUnavailableDescription,
                        errorDescription: coreErrors.serviceUnavailable,
                    }}
                    errorButtons={[
                        {
                            text: coreSharedMessages.buttonBackHome,
                            onClick: onClickErrorButton,
                        },
                    ]}>
                    <Stack spacing="16" mb="4">
                        <Stack spacing="2">
                            <Heading
                                fontSize="2xl"
                                as="h2"
                                fontWeight="semibold">
                                <FormattedMessage
                                    {...primoAddBeneficiariesStepMessages.beneficiariesTitle}
                                />
                            </Heading>
                            <FormattedMessage
                                {...primoAddBeneficiariesStepMessages.beneficiariesDescription}
                            />
                            <Stack spacing="4" mb="2" mt="6">
                                {beneficiariesData && _renderBeneficiaries()}
                            </Stack>
                            {beneficiariesData && (
                                <AddInput
                                    onClick={onOpen}
                                    title={
                                        <FormattedMessage
                                            {...(!canAddBeneficiary
                                                ? primoAddBeneficiariesStepMessages.maxNumberReached
                                                : relatedActionsMessages[
                                                      'health-beneficiaries.add'
                                                  ])}
                                        />
                                    }
                                    isEnabled={canAddBeneficiary}
                                />
                            )}
                        </Stack>
                        {additionalProductsData &&
                            additionalProductsData?.base !== null && (
                                <Stack spacing="8">
                                    <Heading
                                        fontSize="2xl"
                                        as="h2"
                                        fontWeight="semibold">
                                        <FormattedMessage
                                            {...primoAddBeneficiariesStepMessages.additionalContributionTitle}
                                        />
                                    </Heading>
                                    <Alert
                                        displayedTitle={formatMessage(
                                            primoAddBeneficiariesStepMessages.additionalContributionBeneficiariesDescription
                                        )}
                                        icon="InfoIcon"
                                        color="info"
                                        my="0"
                                    />
                                    {_renderExtensionGuaranteeCalculation()}
                                </Stack>
                            )}
                    </Stack>
                    <Stack mt="10">
                        <Button
                            alignSelf="end"
                            colorScheme="primary"
                            size={isMobile ? 'lg' : 'md'}
                            w="fit-content"
                            onClick={() =>
                                handleNextStep({
                                    wizardType: 'primo',
                                })
                            }>
                            <FormattedMessage {...sharedMessages.next} />
                        </Button>
                    </Stack>
                </LayoutBoundary>
            </Container>
        </>
    );
};

export default AddBeneficiaries;
