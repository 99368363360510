import { useEffect } from 'react';
import { Box, Skeleton } from '@chakra-ui/react';
import NewUsername from 'components/NewUsername';
import Alert from 'design-system/components/Alert';
import { primoAccessCreationStepMessage } from '../i18n';
import { useIntl } from 'react-intl';
import { siteConfig } from 'lib/site';
import { useUsername } from '../api';
import { ExtraFormComponentProps } from 'lib/form-wizard/types';

const { appName } = siteConfig;

const PrimoNewUsername = ({ handleError }: ExtraFormComponentProps) => {
    const { formatMessage } = useIntl();
    const { data, status, error } = useUsername(false);

    useEffect(() => {
        if (error) handleError(error);
    }, [handleError, error]);

    return (
        <Box>
            <Skeleton isLoaded={status === 'success'}>
                <NewUsername
                    username={data?.username}
                    contentProps={{
                        maxW: 398,
                        margin: 'auto',
                    }}
                    inputProps={{
                        _readOnly: {
                            bg: 'white',
                            borderColor: 'grey.300',
                        },
                    }}
                    color="primary.main"
                />
            </Skeleton>
            <Skeleton isLoaded={status === 'success'}>
                <Alert
                    color="primary"
                    icon="InfoFlashIcon"
                    description={formatMessage(
                        primoAccessCreationStepMessage.description,
                        { appName }
                    )}
                />
            </Skeleton>
        </Box>
    );
};

export default PrimoNewUsername;
