import { PreferedCanalEnumType } from 'lib/teleconsultation';
import { HealthCareBeneficiary } from './HealthCareBeneficiary';

export type HealthCareAppointment = {
    identifier: string;
    timelineStatus: TimelineStatusEnum;
    status: string;
    creationDate: string;
    preferredCanal: PreferedCanalEnumType;
    beneficiary: Pick<
        HealthCareBeneficiary,
        'identifier' | 'firstName' | 'lastName'
    >;
    speciality: string;
    service: string;
    type: AppointmentTypeEnum;
    appointmentDate: string | null;
    duration: number | null;
};

export enum TimelineStatusEnum {
    ONGOING = 'ongoing',
    FUTURE = 'future',
    PAST = 'past',
}

export enum AppointmentTypeEnum {
    TELECONSULTATIONS = 'teleconsultations',
    PREVENTIONS = 'preventions',
    INTERVENTIONS = 'interventions',
}
