import { camelCase } from 'lodash';
import { MessageDescriptor } from 'react-intl';
import { LinkIntl } from 'core';
import { contractLinks } from '../consts';
import { ContractWithMenu } from '../types';
import { sharedMessages } from 'lib/shared';
import { pageTitles } from 'lib/site';

export const getContractListEntries = (
    contract: ContractWithMenu,
    safeFormatMessage: (
        message: MessageDescriptor,
        values?: Record<string, any>,
        debugKey?: string
    ) => any
) => {
    return contract?.menu?.map(({ item, isAvailable }) => {
        const labelTranslationKey = item?.includes('Products')
            ? 'products'
            : camelCase(item);
        const { path, icon } = contractLinks[item] || {};
        const href = {
            pathname: path.pathname,
            query: {
                ...path?.query,
                ...(path?.pathname?.includes('[id]') && {
                    id: contract?.contract?.contractId,
                    contractType: contract?.contract?.type,
                }),
            },
        };

        return {
            label: safeFormatMessage(
                sharedMessages[labelTranslationKey] ||
                    pageTitles[labelTranslationKey],
                undefined,
                `Contract link label ${labelTranslationKey}`
            ),
            icon,
            isInactive: !path || !isAvailable,
            inactiveLabel: safeFormatMessage(
                sharedMessages.temporarilyUnavailable
            ),
            hasAction: true,
            ...(href && {
                wrapperProps: {
                    as: LinkIntl,
                    href,
                    passHref: true,
                    px: 3,
                },
            }),
        };
    });
};
