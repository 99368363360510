import { Heading, Text, Stack, Box } from '@chakra-ui/react';
import Alert from 'design-system/components/Alert';
import { primoBankDetailsStepMessages } from '../i18n';
import { useBeneficiaryBankDetails } from '../api';
import { FormattedMessage } from 'react-intl';

const BankDetailsStepTopAlert = () => {
    const { data } = useBeneficiaryBankDetails();
    const displayedTitle = data?.requiredSignature
        ? primoBankDetailsStepMessages.bankDetailsRequiredSignatureDescription
        : primoBankDetailsStepMessages.bankDetailsNoRequiredSignatureDescription;
    return (
        <Box>
            <Alert
                displayedTitle={
                    <Text fontWeight="normal" lineHeight="normal">
                        <FormattedMessage {...displayedTitle} />
                    </Text>
                }
                icon="infoFlashIcon"
                color="primary"
                mb={16}
            />
            <Stack gap={4}>
                <Heading
                    as="h2"
                    fontSize="2xl"
                    fontWeight="semibold"
                    wordBreak="break-word">
                    <FormattedMessage
                        {...primoBankDetailsStepMessages.bankDetailsMainTitle}
                    />
                </Heading>
                <Text>
                    <FormattedMessage
                        {...primoBankDetailsStepMessages.bankDetailsMainDescription}
                    />
                </Text>
            </Stack>
        </Box>
    );
};

export default BankDetailsStepTopAlert;
