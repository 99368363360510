import { WidgetKeys } from 'core';
import { GeneralRegime } from 'lib/primo';

export const getPrimoGeneralRegimeForm = (
    generalRegime: GeneralRegime
): WidgetKeys[] => [
    {
        type: 'text',
        title: 'organization-code.title',
        placeholder: 'organization-code.placeholder',
        id: 'organizationCode',
        validations: {
            required: false,
            lengthMax: 9,
            lengthMin: 9,
            allowLetters: false,
            allowSpecialCharacters: false,
        },
        defaultValue: generalRegime?.organizationCode,
    },
    {
        defaultValue: generalRegime?.localRegime,
        description: 'social-security.local-regime.description',
        help: null,
        id: 'localRegime',
        placeholder: null,
        title: null,
        type: 'checkbox',
        validations: {
            required: false,
        },
    },
];
