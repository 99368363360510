import React, { useEffect, useState } from 'react';
import { Button, VStack, Text, Stack, Skeleton } from '@chakra-ui/react';
import { FormattedMessage, useIntl } from 'react-intl';
import { primoHelpMessages } from 'lib/primo/i18n';
import { formatPhoneNumber } from 'core';
import {
    CoreModal,
    CoreModalContent,
    CoreModalHeader,
    CoreModalBody,
    CoreModalFooter,
} from 'design-system/components/Modal';
import { Accordion, Markdown } from 'design-system/components';
import { errors, sharedMessages } from 'lib/shared';
import { useContactCenter } from 'lib/primo/api';

type HelpModalProps = {
    isOpen: boolean;
    onClose: () => void;
    openAccordion?: 'security-social' | 'management-center';
};

enum ACCORDIONS {
    SECURITY_SOCIAL = 'security-social',
    MANAGEMENT_CENTER = 'management-center',
}

const Skeletons = () => (
    <Stack gap={8}>
        <Stack>
            <Skeleton h={4} w={40} />
            <Skeleton h={4} />
        </Stack>

        <Stack>
            <Skeleton h={4} w={40} />
            <Skeleton h={4} />
        </Stack>
    </Stack>
);

function HelpModal({ isOpen, onClose, openAccordion }: HelpModalProps) {
    const [openedAccordion, setOpenedAccordion] = useState<ACCORDIONS>(null);
    const { formatMessage } = useIntl();
    const { data: contactCenter, status: contactStatus } = useContactCenter();

    const onToggle = (accordion: ACCORDIONS) => (isOpen: boolean) => {
        setOpenedAccordion(isOpen ? accordion : null);
    };

    useEffect(() => {
        if (openAccordion) {
            setOpenedAccordion(openAccordion as ACCORDIONS);
        }
    }, [openAccordion]);

    const _renderSecuritySocial = () => (
        <Accordion
            id="accordion-help-security-social"
            isOpen={openedAccordion === ACCORDIONS.SECURITY_SOCIAL}
            onToggle={onToggle(ACCORDIONS.SECURITY_SOCIAL)}
            color="primary"
            title={formatMessage(primoHelpMessages.helpGetSSCTitle)}
            icon="questionCircleIcon"
            withDivider={false}>
            <Text fontWeight="semibold">
                {formatMessage(primoHelpMessages.helpGetSSCDescription)}
            </Text>
        </Accordion>
    );

    const _rednerCenterManagement = () => {
        const descriptor =
            contactStatus === 'error'
                ? errors.unavailableContentText
                : primoHelpMessages.helpContactManagementCenterDescription;
        const values =
            contactStatus === 'error'
                ? {}
                : {
                      tel: formatPhoneNumber.loose(
                          contactCenter?.phoneNumber || ''
                      ),
                      address:
                          (
                              <Markdown
                                  content={contactCenter?.address.replace(
                                      /\\n/g,
                                      '\n'
                                  )}
                              />
                          ) || '',
                  };

        return (
            <Accordion
                isOpen={openedAccordion === ACCORDIONS.MANAGEMENT_CENTER}
                onToggle={onToggle(ACCORDIONS.MANAGEMENT_CENTER)}
                color="primary"
                title={formatMessage(
                    primoHelpMessages.helpContactManagementCenterTitle
                )}
                icon="phoneIcon"
                withDivider={false}>
                {contactStatus === 'pending' ? (
                    <Skeletons />
                ) : (
                    <Text fontWeight="semibold">
                        {formatMessage(descriptor, values)}
                    </Text>
                )}
            </Accordion>
        );
    };

    return (
        <CoreModal isOpen={isOpen} onClose={onClose} size="2xl">
            <CoreModalContent>
                <CoreModalHeader>
                    <FormattedMessage {...primoHelpMessages.helpTitle} />
                </CoreModalHeader>

                <CoreModalBody>
                    <VStack gap={4} alignItems="stretch">
                        {_renderSecuritySocial()}
                        {_rednerCenterManagement()}
                    </VStack>
                </CoreModalBody>

                <CoreModalFooter>
                    <Button w="full" onClick={onClose}>
                        <FormattedMessage {...sharedMessages.close} />
                    </Button>
                </CoreModalFooter>
            </CoreModalContent>
        </CoreModal>
    );
}

export default HelpModal;
