import { WidgetKeys } from 'core';
import { PersonalData } from 'lib/primo';

export const getPrimoSendCodeVerificationForm = (
    personalData: PersonalData
): WidgetKeys[] => {
    const secondPhoneNumber = personalData?.contact?.secondPhoneNumber || '';
    const options = [
        {
            id: personalData?.contact?.mobilePhoneNumber,
            label: personalData?.contact?.mobilePhoneNumber,
        },
    ];
    if (
        secondPhoneNumber.startsWith('+336') ||
        secondPhoneNumber.startsWith('+337')
    ) {
        options.push({
            id: secondPhoneNumber,
            label: secondPhoneNumber,
        });
    }

    return [
        {
            validations: {
                required: true,
            },
            options,
            help: null,
            description: null,
            title: 'signature.phone.phoneSelection.title',
            defaultValue: null,
            placeholder: null,
            type: 'radio',
            id: 'phoneNumber',
        },
    ];
};
