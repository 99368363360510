import { useMemo } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { formatOrganizationCodeValue, formatSocialSecurityNumber } from 'core';
import {
    primoWelcomeMessages,
    primoAddBeneficiariesStepMessages,
} from '../i18n';

export const useBeneficiaryItems = (beneficiariesData) => {
    const { formatDate } = useIntl();

    return useMemo(() => {
        return beneficiariesData?.map((beneficiary) => {
            const items = [
                {
                    title: (
                        <FormattedMessage
                            {...primoWelcomeMessages.welcomeProfileBirthDate}
                        />
                    ),
                    value: formatDate(beneficiary?.birthDate),
                },
                beneficiary?.socialSecurityNumberDetails && {
                    title: (
                        <FormattedMessage
                            {...primoWelcomeMessages.welcomeProfileSocialSecurityNumber}
                        />
                    ),
                    value: formatSocialSecurityNumber.loose(
                        beneficiary?.socialSecurityNumberDetails
                            ?.attachedSocialSecurityNumber
                            ? beneficiary?.socialSecurityNumberDetails
                                  ?.attachedSocialSecurityNumber
                            : beneficiary?.socialSecurityNumberDetails
                                  ?.ownSocialSecurityNumber
                    ),
                    isFullWidth: true,
                },
                beneficiary?.organizationCode !== null && {
                    title: (
                        <FormattedMessage
                            {...primoAddBeneficiariesStepMessages.socialSecurityOrganizationCode}
                        />
                    ),
                    value: formatOrganizationCodeValue(
                        beneficiary?.organizationCode
                    ),
                },
                {
                    title: (
                        <FormattedMessage
                            {...primoAddBeneficiariesStepMessages.teletransmission}
                        />
                    ),
                    value: beneficiary?.otherComplementaryHealthInsurance ? (
                        <FormattedMessage
                            {...primoAddBeneficiariesStepMessages.teletransmissionStatusInactivated}
                        />
                    ) : (
                        <FormattedMessage
                            {...primoAddBeneficiariesStepMessages.teletransmissionStatusDesired}
                        />
                    ),
                },
            ];

            return items.filter((item) => !!item);
        });
    }, [beneficiariesData]);
};
