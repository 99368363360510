import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { SimpleGrid, Stack } from '@chakra-ui/react';
import { coreErrors, formErrorMessages, useInputValidation } from 'core';
import { omit } from 'lodash';
import Fieldset from '../../Fieldset';
import InputBase from '../../InputBase';
import type { WidgetInputProps, WidgetProps } from '../types';
import { useFormContext } from 'react-hook-form';
import { useToast } from 'design-system/components';
import { MessageDescriptor, useIntl } from 'react-intl';
import Alert from 'design-system/components/Alert';

type InputBankDetailsProps = Omit<WidgetProps, 'validations'> & {
    iban: Required<WidgetInputProps>;
    bic: Required<WidgetInputProps>;
    bank: Required<WidgetInputProps>;
    owner: Required<WidgetInputProps>;
    autoCompleteUri?: string | null;
};

function InputBankDetails({
    iban,
    bic,
    bank,
    owner,
    autoCompleteUri,
    ...props
}: InputBankDetailsProps) {
    const [errorsMessages, setErrorsMessages] = useState<MessageDescriptor[]>(
        []
    );
    const { formatMessage } = useIntl();
    const { id, title, description, help } = props;
    const { setValue, watch } = useFormContext();
    const ibanName = `${id}.iban`;
    const bicName = `${id}.bic`;
    const bankName = `${id}.bank`;
    const ownerName = `${id}.owner`;

    const { registerValues: registerValuesIban } = useInputValidation(
        iban.validations,
        ibanName
    );
    const { registerValues: registerValuesBic } = useInputValidation(
        bic.validations,
        bicName
    );
    const { registerValues: registerValuesBank } = useInputValidation(
        bank.validations,
        bankName
    );
    const { registerValues: registerValuesOwner } = useInputValidation(
        owner.validations,
        ownerName
    );

    const errorToast = useToast({
        status: 'error',
        description: formatMessage(coreErrors.serviceUnavailable),
    });

    const autoComplete = useCallback(
        async (ibanValue: string) => {
            try {
                const { data } = await axios.post(
                    `/api/proxy${autoCompleteUri}`,
                    {
                        iban: ibanValue,
                    }
                );
                setValue(bicName, data?.data?.bic, { shouldValidate: true });
                setValue(bankName, data?.data?.bankName, {
                    shouldValidate: true,
                });
            } catch (error) {
                setValue(bicName, '');
                setValue(bankName, '');

                const errorCode = error.response.status;

                if (errorCode === 412) {
                    const errorCodes =
                        error?.response?.data?.errors?.map(
                            (error) => error?.code
                        ) || [];
                    const messages = errorCodes
                        .map((errorCode) => formErrorMessages[errorCode])
                        .filter(Boolean);
                    setErrorsMessages(messages);
                } else {
                    errorToast();
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [autoCompleteUri, bankName, bicName, setValue]
    );

    const ibanValue = watch(ibanName);
    const bicValue = watch(bicName);
    const bankValue = watch(bankName);
    const ibanMinLenghtValidation = iban.validations?.lengthMin;

    useEffect(() => {
        if (autoCompleteUri) {
            if (ibanValue?.length) {
                setErrorsMessages([]);
                // Workaround to check if the IBAN is valid
                // If the IBAN value is valid, send the autocomplete request
                // If a BIC or bank name value is defined and the IBAN is not valid,
                // clear BIC and bank name fileds
                if (ibanValue.length === ibanMinLenghtValidation) {
                    autoComplete(ibanValue);
                } else if (bankValue || bicValue) {
                    setValue(bicName, '');
                    setValue(bankName, '');
                }
            } else {
                setValue(bicName, '');
                setValue(bankName, '');
            }
        }
    }, [
        ibanValue,
        autoCompleteUri,
        ibanMinLenghtValidation,
        autoComplete,
        bicValue,
        bankValue,
        setValue,
        bicName,
        bankName,
    ]);

    return (
        <Fieldset legend={title} {...{ description, help }}>
            <Stack>
                {errorsMessages.map((errorMessage) => (
                    <Alert
                        key={errorMessage.id}
                        displayedTitle={formatMessage(errorMessage)}
                        color="error"
                        icon="WarningIcon"
                    />
                ))}
                <InputBase
                    id={`${id}Iban`}
                    {...registerValuesIban}
                    {...omit(iban, 'show')}
                />
                {(bic.show || bank.show) && (
                    <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
                        {bic.show && (
                            <InputBase
                                id={`${id}Bic`}
                                {...registerValuesBic}
                                {...omit(bic, 'show')}
                                readonly={autoCompleteUri}
                                pointerEvents={
                                    autoCompleteUri ? 'none' : undefined
                                }
                            />
                        )}

                        {bank.show && (
                            <InputBase
                                id={`${id}Bank`}
                                {...registerValuesBank}
                                {...omit(bank, 'show')}
                                readonly={autoCompleteUri}
                                pointerEvents={
                                    autoCompleteUri ? 'none' : undefined
                                }
                            />
                        )}
                    </SimpleGrid>
                )}
                <InputBase
                    id={`${id}Owner`}
                    {...registerValuesOwner}
                    {...omit(owner, 'show')}
                />
            </Stack>
        </Fieldset>
    );
}

export default InputBankDetails;
