import { WidgetKeys } from 'core';
import { UseCGU } from 'lib/user/api/useTerms';

export const getPrimoAccessCreationForm = (gcuData: UseCGU): WidgetKeys[] => [
    {
        type: 'passwordConfirmation',
        id: 'password',
        validations: {
            numberOfRequiredSpecialCharacters: 1,
            numberOfRequiredNumbers: 1,
            numberOfRequiredCapitalLetters: 1,
            numberOfRequiredLowercaseLetters: 1,
            forbiddenCharacters: [],
            lengthMax: 20,
            lengthMin: 8,
            required: true,
        },
        password: {
            title: 'password.new-password.title',
            description: 'password.new-password.description',
        },
        confirmation: {
            title: 'password.confirmation.title',
            description: 'password.confirmation.description',
        },
    },
    {
        id: 'gcuConfirmation',
        type: 'checkboxModal',
        title: 'registration.gcu-confirmation.title',
        modal: {
            text: 'registration.gcu-confirmation.modal.text',
            title: 'registration.gcu-confirmation.title',
            content: gcuData.cgu,
            contentType: 'text/markdown',
        },
        validations: {
            required: true,
        },
        description: 'registration.gcu-confirmation.description',
    },
    {
        validations: {
            required: true,
        },
        options: [
            {
                id: 'yes',
                label: 'commercial-information-consent.yes.label',
            },
            {
                id: 'no',
                label: 'commercial-information-consent.no.label',
            },
        ],
        title: 'commercial-information-consent.title',
        type: 'radio',
        id: 'commercialInformationConsent',
        oneColumn: true,
    },
];
