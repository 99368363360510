import React from 'react';
import { interceptorMessages, useErrorInterceptor } from 'lib/shared';
import { defineMessage, FormattedMessage, useIntl } from 'react-intl';
import { LinkIntl, Heading, coreSharedMessages, useInterceptor } from 'core';
import { useWindowBreakpoints } from 'design-system/hooks';
import { ToastError } from 'design-system/components';
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogOverlay,
    Button,
    Flex,
    Img,
    LinkBox,
    LinkOverlay,
    Stack,
    Text,
} from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { useClearUserData } from 'lib/auth';

const TOKEN_EXCLUDED_PATHS = [
    '/login',
    '/auth/general-conditions',
    '/auth/phone-upgrade',
    '/oauth2-redirect',
    '/oauth2-registration-redirect',
    '/mfa',
    '/mfa/[token]/[strategy]',
    '/reset-password/[providerKey]',
    '/register/activation/[providerKey]',
    '/register/challenge',
    '/register/challenge/[strategy]',
    '/maintenance',
    '/account-validation/[token]',
    '/primo/waiting-validation',
];
const ERROR_EXCLUDED_PATHS = [
    '/login',
    '/mfa',
    '/mfa/[token]/[strategy]',
    '/register/forms',
    '/forgot-password',
    '/forgot-username',
    '/reset-password/[providerKey]',
    '/register/activation/[providerKey]',
    '/register/challenge/[strategy]',
    '/document/[source]',
    '/maintenance',
    '/messages',
    '/messages/[messageId]',
    '/messages/new',
    '/account/beneficiaries',
    '/account/documents',
    '/account/profile',
    '/account/profile/terms-of-use',
    '/help/faq',
    '/help/faq/[id]',
    '/help/faq/questions/[questionId]',
    '/refunds/statements',
    '/refunds',
    '/refunds/[refundId]',
    '/agenda/interventions/[id]',
    '/teleconsultation/[type]',
    '/prevention',
    '/home-consultation',
    '/primo',
];

const intlMessage = defineMessage({
    id: 'components.session-expired-modal.image-alt',
    defaultMessage: 'Icône de session expirée',
});

function TokenExpiredAlertDialog() {
    const { formatMessage } = useIntl();
    const { isMobile } = useWindowBreakpoints();

    return (
        <AlertDialog
            isOpen
            leastDestructiveRef={undefined}
            closeOnEsc={false}
            closeOnOverlayClick={false}
            onClose={() => undefined}
            isCentered={!isMobile}>
            <AlertDialogOverlay>
                <AlertDialogContent
                    rounded="3xl"
                    {...(isMobile && {
                        position: 'absolute',
                        bottom: '-60px',
                        roundedBottom: 'none',
                    })}>
                    <AlertDialogBody
                        p="0"
                        borderBottom="1px solid"
                        borderBottomColor="strokes.medium">
                        <Flex
                            w="full"
                            justifyContent="center"
                            bg="grey.50"
                            py="8"
                            px="4"
                            rounded="3xl"
                            roundedBottom={isMobile && 'none'}>
                            <Stack alignItems="center">
                                <Img
                                    alt={formatMessage(intlMessage)}
                                    src="/images/illustrations/session-expired.svg"
                                    htmlWidth="160px"
                                />
                                <Heading as="h2">
                                    <FormattedMessage
                                        id="errors.timeout-session.title"
                                        defaultMessage="Session expirée"
                                    />
                                </Heading>
                                <Text>
                                    <FormattedMessage
                                        {...interceptorMessages[401].default}
                                    />
                                </Text>
                            </Stack>
                        </Flex>
                    </AlertDialogBody>

                    <AlertDialogFooter py="6">
                        <LinkBox w="full">
                            <LinkOverlay
                                as={LinkIntl}
                                href="/login"
                                passHref
                                outline="unset"
                                w="full">
                                <Button colorScheme="primary" w="full">
                                    <FormattedMessage
                                        {...coreSharedMessages.loginAgain}
                                    />
                                </Button>
                            </LinkOverlay>
                        </LinkBox>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    );
}

function ErrorInterceptor() {
    const router = useRouter();
    const { error } = useInterceptor(useErrorInterceptor);
    const { clearUserData } = useClearUserData();

    if (
        !TOKEN_EXCLUDED_PATHS.includes(router.pathname) &&
        error?.status === 401
    ) {
        clearUserData({ shouldRedirect: false });
        return <TokenExpiredAlertDialog />;
    }

    if (
        !ERROR_EXCLUDED_PATHS.includes(router.pathname) &&
        [400, 403, 404, 500, 503, 504].includes(error?.status)
    ) {
        return <ToastError message={error.message} />;
    }

    return null;
}

export default ErrorInterceptor;
