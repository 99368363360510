import { FormValues } from 'core';
import { Beneficiary } from '../types';

type FormatBeneficiaryDataProps = Omit<
    FormValues,
    'socialSecurityNumberDetails'
> &
    Pick<Beneficiary, 'socialSecurityNumberDetails' | 'beneficiaryType'>;

export const extractValue = (field: any) =>
    field?.value !== undefined ? field.value : field;

export const formatDate = (date: string) => {
    const [day, month, year] = date.split('/');
    return `${year}-${month}-${day}`;
};

export const removeSpaces = (value: { value: string }) => {
    return value?.value?.replace(/\s+/g, '');
};

export const formatBeneficiaryData = (
    data: FormValues,
    beneficiaryType: Beneficiary['beneficiaryType']
): FormatBeneficiaryDataProps => {
    return {
        beneficiaryType: beneficiaryType,
        gender: extractValue(data.gender),
        lastName: extractValue(data.lastName),
        firstName: extractValue(data.firstName),
        birthDate: formatDate(extractValue(data.birthDate)),
        localRegime: extractValue(data.localRegime),
        otherComplementaryHealthInsurance:
            extractValue(data.otherComplementaryHealthInsurance) === 'true',
        organizationCode: extractValue(data.organizationCode) || null,
        socialSecurityNumberDetails: {
            ownSocialSecurityNumber:
                extractValue(
                    removeSpaces(
                        data.socialSecurityNumberDetails
                            ?.ownSocialSecurityNumber
                    )
                ) || null,
            socialSecurityConnection:
                extractValue(
                    removeSpaces(
                        data.socialSecurityNumberDetails
                            ?.socialSecurityConnection
                    )
                ) || null,
            attachedSocialSecurityNumber:
                extractValue(
                    removeSpaces(
                        data.socialSecurityNumberDetails
                            ?.attachedSocialSecurityNumber
                    )
                ) || null,
        },
    };
};
