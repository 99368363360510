import React from 'react';

type ThemeConfigType = {
    partner: string;
    variant: string;
    components: Record<string, Record<string, any>>;
};

type DesignSystemContextType =
    | {
          LinkComponent: React.ElementType;
          themeConfig: ThemeConfigType;
      }
    | null
    | undefined;

type InitialsProps = {
    children: React.ReactNode;
    LinkComponent: React.ElementType;
    themeConfig: ThemeConfigType;
};

const DesignSystemContext = React.createContext<DesignSystemContextType | null>(
    null
);

function useDesignSystemContext() {
    const context = React.useContext(DesignSystemContext);
    if (!context) {
        throw new Error(
            `useDesignSystemContext must be used within a <DesignSystemProvider>`
        );
    }
    return context;
}

function DesignSystemProvider({
    children,
    LinkComponent,
    themeConfig,
}: InitialsProps) {
    return (
        <DesignSystemContext.Provider value={{ LinkComponent, themeConfig }}>
            {children}
        </DesignSystemContext.Provider>
    );
}

export type { DesignSystemContextType };
export { DesignSystemContext, DesignSystemProvider, useDesignSystemContext };
